import React from "react";

const NotFound = () => {
  return (
  
       <div class="not-found-container">
      <h1 className="not-found-h1">404</h1>
      <p className="not-found-p">Oops! La page que vous recherchez est introuvable.</p>
      <a className="not-found-Navlink" href="/home">Retour à la homepage</a>
    </div>
   
  );
};

export default NotFound;
