import ProfileComponent from "../../components/Profile";
import React  from "react";
import { useSelector } from "react-redux";
import { Navigate  } from 'react-router-dom';
const ProfilePage = () => {
  
 // const { user: currentUser } = useSelector((state) => state.auth);
  const { isLoggedIn } = useSelector((state) => state.auth);
  
  if(!isLoggedIn){
    return <Navigate replace to="/"/>
  
  }else{
  return (
    <>
      <ProfileComponent/>
    </>
  );

}
 
};

export default ProfilePage;
