
import { SearchOutlined } from '@ant-design/icons';
import {DatePicker, Button, Input, Space, Table } from 'antd';
import React, { useRef,useState, useEffect } from "react";
import Highlighter from 'react-highlight-words';
//import { DataSource } from 'data/DataSource';
import StatusActions from './StatusActions';
import ButtonActions from './ButtonActions';
import ServiceActions from './ServiceActions';
import UserService from "../../services/user-service";
import exportFromJSON from 'export-from-json'

const moment = require('moment');

const { RangePicker } = DatePicker
//const data = DataSource;


const ListAirtime = (props) => {
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [isLoggedIn, setLoggedIn] = React.useState(false);


  const [content, setContent] = useState("");
  let locale = {
    emptyText: 'pas des transactions traitement en cours',
  };
  
  
  useEffect(() => {
    setLoggedIn(false)
    if(props.userId){
      UserService.getAllAirtime(props.userId).then(
      (response) => {
        setContent(response.data.transaction);
        setLoggedIn(true)
      },
      (error) => {
         // eslint-disable-next-line
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        //setContent(_content);
      }
    );
    }
    
  }, []);

  
  const handleChange = (pagination, filters, sorter) => {
   
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    
  };

  
   
  
  
  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);
  
 
  
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  function convertreturnValue1() {
    if(value !== null){
    var date = new Date(value[0]._d),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
    }
  }

  // function convertreturnValue2() {
  //   if(value !== null){
  //   var date = new Date(value[1]._d),
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2);
  //   return [date.getFullYear(), mnth, day].join("-");
  //   }
  // }
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
    if(value !== null){
      console.log('value1 : ', value[0]._d)
      console.log('value1 : ', convert(value[0]._d))
      console.log('valu2 : ', value[1]._d)
      console.log('value2 : ', convert(value[1]._d))
      
    }
    
  };
   
  

  

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890FF' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#FFC069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  
  const columns = [
    {
      title: 'Service',
        dataIndex: 'transactions',
        key: 'service',
        width:'13%',
        render: (text, record) => {
          return <ServiceActions record={record} />
      },
      filters: [
        {
          text: 'Orange',
          value: 'Orange',
        },
        {
          text: 'Expresso',
          value: 'Expresso',
        },
        {
          text: 'FreeMobile',
          value: 'FreeMobile',
        },
       
        
        
      ],
      filteredValue: filteredInfo.service || null,
      filterSearch: true,
      onFilter: (value, record) => record.service.includes(value),
      sorter: (a, b) => a.service.length - b.service.length,
      sortOrder: sortedInfo.columnKey === 'service' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
        title: 'Numero',
        dataIndex: 'numero',
        key: 'numero',
        
        render: (text, record) => {
  
            return record?.numero
        },
        ...getColumnSearchProps('numero'),
        sorter: (a, b) => a.numero - b.numero,
        sortOrder: sortedInfo.columnKey === 'numero' ? sortedInfo.order : null,
        ellipsis: true,
         
          
         
    },
    {
      title: 'Prix',
      dataIndex: 'transactions',
      key: 'prix',
      render: (text, record) => {

        return record?.montant
    },
    sorter: (a, b) => a.prix - b.prix,
    sortOrder: sortedInfo.columnKey === 'prix' ? sortedInfo.order : null,
    ellipsis: true,
    },
   
  {
    title: 'Date',
    dataIndex: 'transactions',
    key: 'date',
  
    render: (text, record) => {

      return moment(record?.createdAt).format("DD-MM-YYYY HH:mm:ss");
       },
      filters :[
      {
        text:<RangePicker
                value={dates || value}
                disabledDate={disabledDate}
                onCalendarChange={(val) => setDates(val)}
                onChange={(val) => setValue(val)}
                onOpenChange={onOpenChange}
             />,
            value: convertreturnValue1()
       
        
        
      }
    ],
    filteredValue: filteredInfo.date || null,
    onFilter: (value, record) => record.date.includes(value),
    sorter: (a, b) => a.date.length - b.date.length,
    sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
    ellipsis: true,
   
    
      
      
  },
  {
    title: 'Status',
    dataIndex: 'transactions',
    key: 'status',
   
    render: (text, record) => {
      return <StatusActions record={record} />
    
   },
    filters: [
      {
        text: 'INITIATED',
        value: 'INITIATED',
      },
      {
        text: 'FAILED',
        value: 'FAILED',
      },
      {
        text: 'COMPLETED',
        value: 'COMPLETED',
      }
    ],
    filteredValue: filteredInfo.status || null,
      filterSearch: true,
      onFilter: (value, record) => record.status.includes(value),
      ellipsis: true,
  
},
    {
      title: '',
      dataIndex: 'transactions',
      key: 'Action',
      width:'8%',
      render: (text, record) => {
        return <ButtonActions record={record} />
    }}
  ];

  const handleClick = () => {
   
    const newdate =  new Date()
    const fileName = 'listMM'+newdate
    const exportType =  exportFromJSON.types.csv
    console.log("content : ",content);
    var res = [...new Set(content.map(x => x.reponse))];
    console.log("res : ",res);
    exportFromJSON({ content, fileName, exportType })
     
  };
  return (
    <>
      {/* <Space
        style={{
          marginBottom: 16,
        }}
      >
       <Button onClick={handleClick}>Export</Button>
        
        
      </Space>  */}
       <Table
          locale={locale}
          columns={columns} 
          dataSource={content} 
          pagination={{ pageSize: 6}} 
          loading={!isLoggedIn} 
          onChange={handleChange} 
       />
    </>
  );
};
export default ListAirtime;