import axios from "axios";
import authHeader from "./auth-header";
const API_URL = process.env.REACT_APP_API_URL;

const transaction = (customerNum,service,operation, amount,userId) => {

  const data = JSON.stringify({
    "amount": amount,
    "number": customerNum,
    "operation":operation,
    "service": service,
    "userId": userId
  });
  

  const config = {
    method: 'post',
    url: API_URL + "transaction/",
    headers: authHeader(),
      
    data : data
  };
  return axios(config)
  
};


// eslint-disable-next-line import/no-anonymous-default-export
export default {
   transaction  
}