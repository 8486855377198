
import {  Table } from 'antd';
import React, { useState, useEffect } from "react";
import StatusActions from './StatusActions';
import ReapService from '../../services/reap-service';
const moment = require('moment');

const ListDemande = (props) => {
  //const [ setFilteredInfo] = useState({});
  // eslint-disable-next-line
  const [sortedInfo, setSortedInfo] = useState({});
  let locale = {
    emptyText: 'liste vide traitement en cours',
  };
  const [isLoggedIn, setLoggedIn] = React.useState(false);
  const [content, setContent] = useState("");
  useEffect(() => {
    setLoggedIn(false)
    if(props.userId){

      ReapService.getAllReap(props.userId).then(
        (response) => {
          setContent(response.data);
          setLoggedIn(true)
        },
        (error) => {
          // eslint-disable-next-line
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
  
         // setContent(_content);
        }
      );
    }
   
  }, []);
  

  
  

  
  
  const columns = [
   
    {
        title: 'Montant',
        dataIndex: 'montant',
        key: 'montant',
        
        render: (text, record) => {
  
            return record?.amount +" F"
        },
      
        sorter: (a, b) => a.montant - b.montant,
        sortOrder: sortedInfo.columnKey === 'montant' ? sortedInfo.order : null,
        ellipsis: true,
         
          
         
    },
    
  {
    title: 'Date',
    dataIndex: 'transactions',
    key: 'date',
  
    render: (text, record) => {

      return moment(record?.createdAt).format("DD-MM-YYYY HH:mm:ss");
       },
    
   
    sorter: (a, b) => a.date.length - b.date.length,
    sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
    ellipsis: true,
  },
  {
    title: 'Status',
    dataIndex: 'transactions',
    key: 'status',
   
    render: (text, record) => {
      return <StatusActions record={record} />
  },
  sorter: (a, b) => a.type.length - b.type.length,
  sortOrder: sortedInfo.columnKey === 'montant' ? sortedInfo.order : null,
  ellipsis: true,
   
}
  ];
  return (
    <>
      {/* <Space
        style={{
          marginBottom: 16,
        }}
      >
        <Button onClick={setAgeSort}>Sort heure</Button>
        <Button onClick={clearFilters}>Clear filters</Button>
        <Button onClick={clearAll}>Clear filters and sorters</Button>
      </Space> */}
        <Table
          locale={locale}
          columns={columns} 
          dataSource={content} 
          pagination={{ pageSize: 4}} 
          loading={!isLoggedIn} 
         
       />
    </>
  );
};
export default ListDemande;