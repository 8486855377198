import React from "react";
import ModalProvider from "react-modal";

import { Column, Img, Text } from "components";

const MMencoursModal = (props) => {
  return (
    <>
      <ModalProvider
       // appElement={document.getElementById("root")}
        className="m-[auto]  justify-center items-center  w-[40%] "
        overlayClassName="bg-black_900_60 fixed flex h-[100%] inset-y-[0] w-[100%]"
        {...props}
      >
        <div className="m-[auto] max-h-[97vh] items-center  w-[-100%]">
          <Column className="bg-gray_102 items-center justify-start lg:p-[26px] xl:p-[36px] 2xl:p-[36px] 3xl:p-[439px] rounded-radius16 w-[100%]">
           
           
            {props.successful ? (
              <>
                      <Img
                      src="images/img_close.svg"
                      className="common-pointer test_1200x630waTwo"
                      onClick={props.onRequestClose}
                      alt="close"
                    />
                    <Text className="columnclose2" as="h3" variant="h3">
                      Votre demande est en cours de traitement
                    </Text>
                    <Img
                      src="images/img_sandclock1.png"
                      className="sandclockOne"
                      alt="sandclockOne"
                    />
                    <span className="spinner-border spinner-border-sm"></span>
                </>
                  ):
                   <>   
                      {props.status === 200 ? (
                        <>
                         <Img
                            src="images/img_close.svg"
                            className="common-pointer test_1200x630waTwo"
                            onClick={props.onRequestClose}
                            alt="close"
                          />
                          <Text className="columnclose2" as="h3" variant="h3">
                          Votre  demande  est en cours
                         
                          </Text>
                          <Img
                            src="images/valide.png"
                            className="sandclockOne"
                            alt="sandclockOne"
                          />
                      </>
                     ):
                  <>
                    <Img
                      src="images/img_close.svg"
                      className="common-pointer test_1200x630waTwo"
                      onClick={props.onRequestClose}
                      alt="close"
                    />
                    <Text className="columnclose2" as="h3" variant="h3">
                      Votre demande est échoue
                    </Text>
                    <Img
                      src="images/invalid.jpg"
                      className="sandclockOne"
                      alt="sandclockOne"
                    />
                    <Text className="columnclose2 alert alert-danger" as="h4" variant="h4">
                            {props.message} 
                          
                      </Text>
                  </>
                  }
                     </>
                  }
          </Column>
        </div>
      </ModalProvider>
    </>
  );
};

export { MMencoursModal };
