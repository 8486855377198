import {Space} from 'antd';
import { Button,Img } from 'components/';
import React, { useState } from 'react';




export default function ServiceActions(props) {

    const service = props?.record?.service
    const [loading] = useState(false)
    
    if (loading) return <Button type="primary" shape="circle" loading />
    if (service === 'Orange') return (
        <Button
            className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
            variant="icbFillGray100"
        >
            <Img
             src="images/orange_logo.svg"
            className="flex items-center justify-center"
            alt="GroupFortyFive"
            />
        </Button>
       
    )

    if (service === 'Expresso') return (
        <Button
            className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
            variant="icbFillGray100"
        >
            <Img
            src="images/logo-expresso.png"
            className="flex items-center justify-center"
            alt="GroupFortyFive"
            />
        </Button>
    )

    if (service === 'Free') return (
        <Button
            className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
            variant="icbFillGray100"
        >
            <Img
            src="images/Free_mobile_2011.svg"
            className="flex items-center justify-center"
            alt="GroupFortyFive"
            />
        </Button>
    )
   
    return (
        <Space size='middle' >
            
        </Space>
    )

}