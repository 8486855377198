import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Row, Stack, Button, Img, Text, Column, Line, Input } from "../../components";
import Sidebar from "../../components/Sidebar/Sidebar";
import Sidebarleft from "../../components/Sidebarleft";
import { Navigate,useNavigate } from "react-router-dom";

import { RechargerFact } from "../../actions/facturiers";
import UserService from "../../services/user-service";
import FcService from "../../services/gtu-fc-service";
import { clearMessage } from "../../actions/message";
import { OMpayencoursModal } from "../../modals/OMpayencours";
import {OMpaymodalModal} from "../../modals/OMfcmodal"

const FromSenEau = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  

  const navigate = useNavigate();
  const [isOpenModalhomeModal, setModalhomeModal] = React.useState(false);
  const [isOMpaymodalModal, setOMpaymodalModal] = React.useState(false);
 
 
 
  const {type,operation,service,img, num,url_r} =props;

  const  [number, setNumber]  = React.useState ( "" );
  const  [numberC, setNumberC]  = React.useState ( "" );
  const  [montant, setMontant]  = React.useState ( "" );
  const [response,setResponse] =React.useState (  );
  const [error, setError] = useState(null);
  const [error1, setError1] = useState(null);
  const [successful, setSuccessful] = useState(false);
  let calssSoled = true;
  const { status } = useSelector(state => state.status);

  const { isLoggedIn } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();
  const [balance, setBalance] = useState("");
 
  useEffect(() => {
   
  //   if (!currentUser || !isLoggedIn) {
  //     navigate("/");
  //  }
    if(currentUser){
       
        UserService.getBalance(currentUser.response.user.id).then(
          (response) => {
            setBalance(response.data);
          },
          (error) => {
            // eslint-disable-next-line
            const _content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
    
              //setBalance(_content);
          }
        );
      }

    
   
  },
  // eslint-disable-next-line
   [])
  ;
 

 
   
  if(100000 >= parseInt(balance.solde) ){
    calssSoled = false
  }


  

  function isValidNumber(setNumber) {
    return  /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{3})$/.test(setNumber);
 }
 const isEnabled = (number.length > 8 &&   numberC.length > 0)
const onChangeNumber = (e) => {
    if (!isValidNumber(e.target.value)) {
      setError1('NUMERO INVALIDE');
      
    } else {
      setError1(null);
    }
    
    setNumber(e.target.value);
  };
  

  
 
   
  
  
  const  handleSubmit =  (e) => {
    e.preventDefault();
    if(currentUser){

      setModalhomeModal(true);
      setOMpaymodalModal(false)
      setSuccessful(true);
    
      dispatch(RechargerFact(numberC,number,service,operation, montant,currentUser.response.user.id))
        .then(() => {
          setSuccessful(false);
          
           // window.location.reload();
        })
        .catch(() => {
          setSuccessful(false);
        });

    }
   
  
  };

  const  handleSubmitInfos =  (e) => {
    e.preventDefault();
      if(currentUser){
        setSuccessful(true);
        FcService.getInfos(numberC,"SenEau","payment",currentUser.response.user.id).then(
            (res) => {
              setResponse(res.data);
              if(res.data.response.status ==="COMPLETED"){
                setSuccessful(false);
                setMontant(res.data.response.infos.montant)
                setOMpaymodalModal(true);

              }else{
                setSuccessful(false);
                setError(res.data.response.message)
              }
            },
            (error) => {
              // eslint-disable-next-line
              const _content =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
                setSuccessful(false);
      
                //setBalance(_content);
            }
          );
          } 
  };
 
  

  // const showModal = () => {
  //   if(number ===""){
  //     return setOMpaymodalModal(false);
  //   }
  //   setOMpaymodalModal(true);
  // };

  
  
  function handleNavigate17() {
    navigate(url_r);
  }
  function handleCloseModalhomeModal() {
    setModalhomeModal(false);
    //dispatch(clearMessage()); // clear message when changing location
    window.location.reload();

  }

  function handleCloseMpaymodalModal() {
    setOMpaymodalModal(false);
    dispatch(clearMessage()); // clear message when changing location

  }
 

  if(!isLoggedIn){
    return <Navigate replace to="/"/>
  
  }else{
  return (
    <>
      <Row className="bg-bluegray_50 font-montserrat items-center mx-[auto] lg:pr-[21px] xl:pr-[27px] 2xl:pr-[30px] 3xl:pr-[36px] w-[100%]">
        <Sidebar  />
        <Stack className="3xl:h-[1050px] lg:h-[780px] xl:h-[900px] 2xl:h-[1040px] lg:ml-[21px] xl:ml-[27px] 2xl:ml-[30px] 3xl:ml-[36px] w-[80%]">
        
          <Stack className="absolute 3xl:h-[1050px] lg:h-[622px] xl:h-[778px] 2xl:h-[875px] w-[100%]">
           
           
            <Column className="absolute bg-gray_50 items-center justify-start left-[0] lg:py-[25px] xl:py-[32px] 2xl:py-[36px] 3xl:py-[43px] rounded-radius16 w-[78%]">
              <Column className="items-center justify-start lg:mb-[139px] xl:mb-[174px] 2xl:mb-[196px] 3xl:mb-[235px] w-[100%]">
                <Row className="items-start justify-between w-[92%]">
                  <Row className="items-start justify-between w-[23%]">
                    <Button
                      className="common-pointer flex items-center justify-center lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] 3xl:mt-[6px] rounded-radius50 test_1200x630waTwo"
                      onClick={handleNavigate17}
                      variant="icbFillGray201"
                    >
                      <Img
                        src="images/img_arrowleft.svg"
                        className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                        alt="arrowleft"
                      />
                    </Button>
                    <Button
                      className="flex lg:h-[30px] xl:h-[37px] 2xl:h-[41px] 3xl:h-[50px] items-center justify-center lg:w-[29px] xl:w-[36px] 2xl:w-[40px] 3xl:w-[49px]"
                      variant="icbFillWhiteA701"
                    >
                      <Img
                        src={img}
                        className="flex items-center justify-center lg:h-[20px] xl:h-[25px] 2xl:h-[28px] 3xl:h-[34px]"
                        alt="GroupSeven"
                      />
                    </Button>
                    <Text className="rowarrowleft" as="h3" variant="h3">
                     {type}
                    </Text>
                  </Row>
                  <Row className="font-inter items-start justify-between mt-[1px] w-[30%]">
                    <Text
                      className="text-black_900 w-[auto]"
                      as="h3"
                      variant="h3"
                    >
                      <span className="text-gray_600 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                        Solde{" "}
                      </span>
                      <span  className={ calssSoled ? "text-bluegray_600 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" : "text-amber_A700 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" } >
                     {new Intl.NumberFormat('de-DE').format(balance.solde)} Fcfa
                    </span>
                    </Text>
                    {/* <Img
                      src="images/img_ueyeslash.svg"
                      className="ueyeslash_One"
                      alt="ueyeslash"
                    /> */}
                  </Row>
                </Row>
                
                <Line className="bg-black_900_19 h-[1px] lg:mt-[19px] xl:mt-[24px] 2xl:mt-[27px] 3xl:mt-[33px] w-[100%]" />
                <Column className="justify-start lg:mt-[25px] xl:mt-[32px] 2xl:mt-[36px] 3xl:mt-[43px] w-[93%]">
                  
                  <Text className="Numrodubnf2" as="h3" variant="h3">
                    Numéro {num}
                  </Text>
                  <Input
                    className="placeholder:text-black_900_b2 GroupFour"
                    wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                    name="GroupFour"
                    
                    value={numberC}
                    
                    onChange = { e  => setNumberC ( e.target.value)}
                  ></Input>
                  {error && <h2 style={{color: 'red'}}>{error}</h2>}
                  <Text className="Numrodubnf2" as="h3" variant="h3">
                    Numéro téléphone
                  </Text>
                  <Input
                    className="placeholder:text-black_900_b2 GroupFour"
                    wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                    name="GroupFour"
                    type="tel"
                    maxlength="9"
                    placeholder="7X XXX XX XX"
                    value={number}
                    
                    onChange = { onChangeNumber}
                   
                  ></Input>
                  {error1 && <h2 style={{color: 'red'}}>{error1}</h2>}
                  
                  <Button
                    className="common-pointer font-semibold lg:mt-[43px] xl:mt-[54px] 2xl:mt-[60px] 3xl:mt-[73px] lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px] text-center w-[100%]"
                    onClick={handleSubmitInfos}
                    shape="RoundedBorder8"
                    size="md"
                    disabled={!isEnabled}
                    //disabled={successful}
                    >
                     {successful && (
                   <span className="spinner-border spinner-border-sm"></span>
                   )}
                    Valider la transaction
                  </Button>
                </Column>

              </Column>
            </Column>
          </Stack>
          <Sidebarleft username={currentUser ? (currentUser.response.user.username):null}/>
        </Stack>
      </Row>

      
      {isOpenModalhomeModal ? (
        <OMpayencoursModal
          isOpen={isOpenModalhomeModal}
          onRequestClose={handleCloseModalhomeModal}
          successful={successful}
          status = {status}
          message = {message}
          msg={`La transaction réussie`}
        />
      ) : null}
      {isOMpaymodalModal ? (
        <OMpaymodalModal
          isOpen={isOMpaymodalModal}
          onRequestClose={handleCloseMpaymodalModal}
          type={`Facturiers  ${type}  ${response.response.infos.prenom}`} 
          montant = {montant}
          number={number}
          submit ={handleSubmit}
      
          
        />
      ) : null}
    </>
  );
  }
};

export default FromSenEau;
