export const VALIDE_SUCCESS = "VALIDE_SUCCESS";
export const VALIDE_FAIL = "VALIDE_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_STATUS = "SET_STATUS";
export const CLEAR_STATUS = "CLEAR_STATUS";