import React, { useState, useEffect } from "react";

import { Row, Column, Img, Text, Stack, Button } from "../../components";

import { useNavigate } from "react-router-dom";
import ListFacturier from "../../components/ListFacturier";
import UserService from "../../services/user-service";
import { EyeInvisibleTwoTone,EyeTwoTone } from '@ant-design/icons';
const Solde = (props) => {
  const navigate = useNavigate();

  let calssSoled = true;
  const [balance, setBalance] = useState("");
  const [showBalanceIcon, setShowBalanceIcon] = useState(false);
  useEffect(
    () => {
      if (props.userId) {
        UserService.getBalance(props.userId).then(
          (response) => {
            setBalance(response.data);
          },
          (error) => {
            const _content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();

            setBalance(_content);
          }
        );
      }
    },
    // eslint-disable-next-line
    []
  );

  if (100000 >= parseInt(balance.solde)) {
    calssSoled = false;
  }

  function handleBalanceIconClick() {
    setShowBalanceIcon(!showBalanceIcon);
  }
  function handleNavigateSenelec() {
    navigate("/senelec");
  }
  function handleNavigateRapido() {
    navigate("/rapido");
  }
  function handleNavigateWayfal() {
    navigate("/Woyofal");
  }
  function handleNavigateSenEau() {
    navigate("/SenEau");
  }
  return (
    <>
      <aside className={props.className}>
        <div className="">
          <Column className="absolute justify-start left-[0] top-[0] w-[78%]">
            <Column className="bg-gray_50 font-inter items-center justify-end lg:p-[20px] xl:p-[26px] 2xl:p-[29px] 3xl:p-[35px] rounded-radius16 w-[100%]">
              {/* <Stack className="lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px] lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[30%]"> */}
              {showBalanceIcon?
             (<Row className="items-center justify-center lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[36%]">
             <Text className="text-black_900 w-[auto]" as="h1" variant="h1">
               <span className="text-gray_600 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                 Solde{" "}
               </span>
               <span  className={ calssSoled ? "text-bluegray_600 p-2 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" : "text-amber_A700 p-2 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" } >
               ******** Fcfa
                 </span>
             </Text>
             <button
             onClick={handleBalanceIconClick}> 
               <EyeTwoTone twoToneColor="#674788" size="60px" /> 
             </button>
             {/* <Img
               src="images/img_ueyeslash.svg"
               className="lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px] "
               alt="ueyeslash"
               onClick={handleBalanceIconClick}
             /> */}
           </Row>)
             :(
              <Row className="items-center justify-center lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[36%]">
              <Text className="text-black_900 w-[auto]" as="h1" variant="h1">
                <span className="text-gray_600 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                  Solde{" "}
                </span>
                <span  className={ calssSoled ? "text-bluegray_600 p-2 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" : "text-amber_A700 p-2 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" } >
                   {new Intl.NumberFormat('de-DE').format(balance.solde)} Fcfa
                  </span>
              </Text>
              <button  onClick={handleBalanceIconClick}> <EyeInvisibleTwoTone  twoToneColor="#674788" size="50px" title=" Masquer Solde"/> </button>
              {/* <Img
                src="images/img_ueyeslash.svg"
                className="lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px] "
                alt="ueyeslash"
                onClick={handleBalanceIconClick}
              /> */}
            </Row>
             )}
              {/* </Stack> */}
              {/* //Abonnement */}
              <Column className="font-montserrat justify-start lg:mt-[23px] xl:mt-[29px] 2xl:mt-[33px] 3xl:mt-[40px] w-[99%]">
                <Text
                  className="font-semibold text-black_900 w-[auto]"
                  as="h4"
                  variant="h4"
                >
                  Facturiers
                </Text>
                <Stack className="lg:h-[104px] xl:h-[130px] 2xl:h-[146px] 3xl:h-[175px] lg:mt-[12px] xl:mt-[16px] 2xl:mt-[18px] 3xl:mt-[21px] w-[100%]">
                  <Column className="absolute bottom-[1%] items-center justify-start w-[100%]">
                    <Column className="justify-start w-[100%]">
                      <Row className="items-center justify-between w-[100%]">
                        <Row className="items-center justify-between w-[80%]">
                          <Row
                            onClick={handleNavigateSenelec}
                            className=" disabledbutton bg-gray_100 items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius12 w-[24%]"
                          >
                            <Button
                              className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
                              variant="icbFillGray100"
                            >
                              <Img
                                src="images/img_logosenelec1.png"
                                className="flex items-center justify-center"
                                alt="GroupSeventyOne"
                              />
                            </Button>
                            <Text
                              className="rowgroupseventyone"
                              as="h3"
                              variant="h3"
                            >
                              Senelec
                            </Text>
                          </Row>
                          <Row className="disabledbutton bg-gray_100 items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius12 w-[24%]">
                            <Img
                              src="images/img_33922240503097.png"
                              className="test_27332168912906_One"
                              alt="33922240503097 Two"
                            />
                            <Text
                              className="rowgroupseventyone"
                              as="h3"
                              variant="h4"
                            >
                              TNT Escaf
                            </Text>
                          </Row>
                          <Row
                            onClick={handleNavigateWayfal}
                            className="btn bg-gray_100 items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius12 w-[24%]"
                          >
                            <Stack className="bg-white_A700 px-[1px] test_27332168912906_One">
                              <Img
                                src="images/img_logowoyofal01.png"
                                className="absolute lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px] inset-[0] justify-center m-[auto] w-[95%]"
                                alt="LOGOWOYOFALOne One"
                              />
                            </Stack>
                            <Text
                              className="rowgroupseventyone"
                              as="h3"
                              variant="h3"
                            >
                              Woyofal
                            </Text>
                          </Row>
                          <Row className="disabledbutton bg-gray_100 items-center 2xl:p-[10px] 3xl:p-[12px] lg:p-[7px] xl:p-[9px] rounded-radius12 w-[24%]">
                            <Button
                              className="flex lg:h-[31px] xl:h-[39px] 2xl:h-[43px] 3xl:h-[52px] items-center justify-center mb-[1px] lg:w-[30px] xl:w-[38px] 2xl:w-[42px] 3xl:w-[51px]"
                              variant="icbFillBlack900"
                            >
                              <Img
                                src="images/img_group46.png"
                                className="flex items-center justify-center"
                                alt="GroupSeventyFour"
                              />
                            </Button>
                            <Text
                              className="rowgroupseventyone"
                              as="h3"
                              variant="h3"
                            >
                              Canal+
                            </Text>
                          </Row>
                        </Row>
                        <Row
                          onClick={handleNavigateRapido}
                          className=" bg-gray_100 items-center 2xl:p-[10px] 3xl:p-[12px] lg:p-[7px] xl:p-[9px] rounded-radius12 w-[19%]"
                        >
                          <Button
                            className="flex lg:h-[31px] xl:h-[39px] 2xl:h-[43px] 3xl:h-[52px] items-center justify-center mb-[1px] lg:w-[30px] xl:w-[38px] 2xl:w-[42px] 3xl:w-[51px]"
                            variant="icbFillWhiteA700"
                          >
                            <Img
                              src="images/img_group47.png"
                              className="flex items-center justify-center lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px]"
                              alt="GroupSeventyFive"
                            />
                          </Button>
                          <Text
                            className="rowgroupseventyone"
                            as="h3"
                            variant="h3"
                          >
                            Rapido
                          </Text>
                        </Row>
                      </Row>
                      <Row
                        onClick={handleNavigateSenEau}
                        className="btn bg-gray_100 items-center lg:mt-[10px] xl:mt-[13px] 2xl:mt-[15px] 3xl:mt-[18px] xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius12 w-[19%]"
                      >
                        <Stack className="bg-white_A700 px-[4px] test_27332168912906_One">
                          <Img
                            src="images/img_getfile2_10X35.png"
                            className="absolute 2xl:h-[10px] 3xl:h-[12px] lg:h-[7px] xl:h-[9px] inset-[0] justify-center m-[auto] w-[80%]"
                            alt="GetFileTwo"
                          />
                        </Stack>
                        <Text
                          className="rowgroupseventyone"
                          as="h3"
                          variant="h3"
                        >
                          Sen'Eau
                        </Text>
                      </Row>
                    </Column>
                  </Column>
                </Stack>
              </Column>
            </Column>
            <Text className="columnhistoriquedes" as="h3" variant="h3">
              Historique des transactions
            </Text>
            <ListFacturier userId={props.userId} />
          </Column>
        </div>
      </aside>
    </>
  );
};

export default Solde;
