import {Space} from 'antd';
import { Button,Img } from 'components/';
import React, { useState } from 'react';




export default function StatusActions(props) {

    const service = props?.record?.service
    const [loading] = useState(false)
   
   
    if (loading) return <Button type="primary" shape="circle" loading />
    if (service === 'Senelec') return (
        <Button
            className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
            variant="icbFillGray100"
        >
            <Img
            src= "images/img_logosenelec1.png"
            className="flex items-center justify-center"
            alt="Senelec"
            />
        </Button>
       
    )

    if (service === 'woyofal') return (
        <Button
            className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
            variant="icbFillGray100"
        >
            <Img
            src= "images/img_logowoyofal01.png"
            className="flex items-center justify-center"
            alt="Woyofal"
            />
        </Button>
    )
    if (service === 'Rapido') return (
        <Button
            className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
            variant="icbFillGray100"
        >
            <Img
            src= " images/img_group47.png"
            className="flex items-center justify-center"
            alt="Rapido"
            />
        </Button>
    )
   
    return (
        <Space size='middle' >
            
        </Space>
    )

}