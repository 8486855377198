import { Table } from 'antd';
//import { DataFac } from 'data/DataFac';
import React, { useState,useEffect } from 'react';
import UserService from "../../services/user-service";
import StatusActions from './StatusActions';
import ServiceActions from './ServiceActions'
import ButtonActions from './ButtonActions';
const moment = require('moment');
//const data = DataFac;
const ListFacturier = (props) => {

  let locale = {
    emptyText: 'pas des transactions traitement en cours',
  };
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [isLoggedIn, setLoggedIn] = React.useState(false);
  const [content, setContent] = useState("");

  useEffect(() => {
    setLoggedIn(false)
    if(props.userId){
      UserService.getAllFact(props.userId).then(
        (response) => {
          setContent(response.data.transaction);
          setLoggedIn(true)
        },
        (error) => {
          // eslint-disable-next-line
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
  
         // setContent(_content);
        }
      );
    }
   
  }, []);

  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
 
  const columns = [
    {
      title: 'Service',
        dataIndex: 'transactions',
        key: 'service',
        width:'13%',
        render: (text, record) => {
          return <ServiceActions record={record} />
      },
        filters: [
          {
            text: 'Woyofal',
            value: 'Woyofal',
          },
          {
            text: 'Senelec',
            value: 'Senelec',
          }
        ],
        filteredValue: filteredInfo.service || null,
        onFilter: (value, record) => record.service.includes(value),
        // sorter: (a, b) => a.service.length - b.service.length,
        // sortOrder: sortedInfo.columnKey === 'service' ? sortedInfo.order : null,
        ellipsis: true,
    },

    {
        title: 'Numero',
        dataIndex: 'transactions',
        key: 'numero',
        render: (text, record) => {
  
            return record?.numero
        },
          sorter: (a, b) => a.numero - b.numero,
          sortOrder: sortedInfo.columnKey === 'numero' ? sortedInfo.order : null,
          ellipsis: true,
    },
    {
      title: 'Phone',
      dataIndex: 'transactions',
      key: 'numero_phone',
      render: (text, record) => {

          return record?.phone
      },
        sorter: (a, b) => a.numero_phone - b.numero_phone,
        sortOrder: sortedInfo.columnKey === 'numero_phone' ? sortedInfo.order : null,
        ellipsis: true,
  },
    {
      title: 'Montant',
      dataIndex: 'transactions',
      key: 'montant',
      render: (text, record) => {

        return record?.montant
    },
    sorter: (a, b) => a.montant - b.montant,
    sortOrder: sortedInfo.columnKey === 'montant' ? sortedInfo.order : null,
    ellipsis: true,
    },
    
  {
    title: 'Date',
    dataIndex: 'transactions',
    key: 'date',
  
    render: (text, record) => {

      return moment(record?.createdAt).format("DD-MM-YYYY HH:mm:ss");
       },
    sorter: (a, b) => a.date.length - b.date.length,
    sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
    ellipsis: true,
  },
  {
    title: 'Status',
    dataIndex: 'transactions',
    key: 'status',
    width:'12%',
    render: (text, record) => {
      return <StatusActions record={record} />
  },
    filters: [
      {
        text: 'INITIATED',
        value: 'INITIATED',
      },
      {
        text: 'FAILED',
        value: 'FAILED',
      },
      {
        text: 'COMPLETED',
        value: 'COMPLETED',
      }
    ],

    filteredValue: filteredInfo.status || null,
    onFilter: (value, record) => record.status.includes(value),
    // sorter: (a, b) => a.service.length - b.service.length,
    // sortOrder: sortedInfo.columnKey === 'service' ? sortedInfo.order : null,
    ellipsis: true,
   
   
},
  
  {
      title: '',
      dataIndex: 'transactions',
      key: 'Action',
      width:'8%',
      render: (text, record) => {
        return <ButtonActions record={record} />
      }
  }
  ];


  return (
    <>
      {/* <Space
        style={{
          marginBottom: 16,
        }}
      >
        <Button onClick={setAgeSort}>Sort heure</Button>
        <Button onClick={clearFilters}>Clear filters</Button>
        <Button onClick={clearAll}>Clear filters and sorters</Button>
      </Space> */}
      <Table
          locale={locale}
          columns={columns} 
          dataSource={content} 
          pagination={{ pageSize: 6}} 
          loading={!isLoggedIn} 
          onChange={handleChange} 
       />
    </>
  );
};
export default ListFacturier;