import React from "react";
import ModalProvider from "react-modal";

import { Column, Text, Row, Button } from "components";


const OMpaymodalModal = (props) => {
 

  return (
    <>
      <ModalProvider
        
       
        className="m-[auto] w-[40%]"
        overlayClassName="bg-black_900_60 fixed flex h-[100%] inset-y-[0] w-[100%]"
        {...props}
      >
        <div className="m-[auto] max-h-[97vh] overflow-y-auto">
          <Column className="bg-gray_101 items-center justify-end lg:p-[14px] xl:p-[17px] 2xl:p-[19px] 3xl:p-[23px] rounded-radius16 w-[100%]">
            <Text
              className="lg:mt-[56px] xl:mt-[71px] 2xl:mt-[80px] 3xl:mt-[96px] text-black_900_cc w-[auto]"
              as="h3"
              variant="h3"
            >
              Récapitulatif de la transaction
            </Text>
            <Column className="justify-start lg:mt-[34px] xl:mt-[43px] 2xl:mt-[48px] 3xl:mt-[58px] w-[43%]">
              <Text
                className="font-semibold text-black_900_cc w-[auto]"
                as="h4"
                variant="h4"
              >
                Type de transaction
              </Text>
              <Text className="Retrait" as="h4" variant="h4">
                {props.type}
              </Text>
              <Text className="Numrodubnf" as="h4" variant="h4">
                Numéro du bénéficiaire
              </Text>
              <Text className="test_221772346" as="h4" variant="h4">
                {props.number}
              </Text>
              
              <Text className="Numrodubnf" as="h4" variant="h4">
                Montant de la transaction
              </Text>
              <Text className="fcfaCounter" as="h4" variant="h4">
               {props.montant}
              </Text>
              <Row className="items-center justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[100%]">
                <Button
                  className="common-pointer font-semibold lg:text-[8px] xl:text-[13px] 2xl:text-[12px] 3xl:text-[18px] text-center w-[47%]"
                  onClick={props.onRequestClose}
                  shape="RoundedBorder8"
                  size="md"
                  variant="FillBluegray100"
                >
                  Annuler
                </Button>
                <Button
                  className="font-semibold lg:text-[8px] xl:text-[10px] 2xl:text-[12px] 3xl:text-[18px] text-center w-[47%]"
                  shape="RoundedBorder8"
                  size="md"
                  onClick={props.submit}
                >
                  Confirmer
                </Button>
              </Row>
            </Column>
          </Column>
        </div>
      </ModalProvider>
    </>
  );
};

export { OMpaymodalModal };
