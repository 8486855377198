import React, { useState, useEffect,useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Row,
  Stack,
  Button,
  Img,
  Text,
  Column,
  Line,
  Input,
} from "./../../components";
import Sidebar from "./../../components/Sidebar/Sidebar";
import Sidebarleft from "../../components/Sidebarleft";
import { Navigate, useNavigate } from "react-router-dom";
import { Modal } from "antd";
import ListAppel from "../../components/ListAppelsFond";
import { verifyPhoneOtp } from "../../actions/user";
import UserService from "../../services/user-service";
import { logout } from "../../actions/auth";
import { create } from "../../actions/appelfond";
import OTPInput from "otp-input-react";
import { clearMessage } from "../../actions/message";
import { MMencoursModal } from "./../../modals/RMencours";
const AppelFondPage = () => {
  let navigate = useNavigate();
  
  const { user: currentUser } = useSelector((state) => state.auth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [montant, setMontant] = React.useState("");
  const [otp, setOtp] = React.useState("");

  let calssSoled = true;

  const [successful, setSuccessful] = useState(false);
  const { message } = useSelector((state) => state.message);
  const { status } = useSelector((state) => state.status);
  const [isdisabled, setDisabled] = React.useState(false);
  const dispatch = useDispatch();
  const [isOpenModalhomeModal, setModalhomeModal] = React.useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);

  const [balance, setBalance] = useState("");

  useEffect(
    () => {
      if (currentUser) {
        UserService.getBalance(currentUser.response.user.id).then(
          (response) => {
            setBalance(response.data);
            dispatch(clearMessage()); // clear message when changing location
          },
          (error) => {
            const _content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();

            //setBalance(_content);
          }
        );
      }
    },
    // eslint-disable-next-line
    []
  );

  if (100000 >= parseInt(balance.solde)) {
    calssSoled = false;
  }

  function isValidMontant(montant) {
    if (!montant) return true;
  }

  const onChangeMontant = (e) => {
    if (isValidMontant(e.target.value)) {
      setDisabled(false);
    }
    if (!isValidMontant(e.target.value)) {
      setDisabled(true);

      //const m = e.target.value;
      setMontant(e.target.value);
    } else {
      setMontant(null);
    }
  };
  const Logout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (currentUser) {
      setSuccessful(true);
      dispatch(verifyPhoneOtp(currentUser.response.user.id, otp))
        .then(() => {
          setIsModalOpen(false);
          setModalhomeModal(true);
          dispatch(
            create(
              parseInt(montant),
              currentUser.response.user.username,
              currentUser.response.user.id,
              currentUser.response.user.created_by
            )
          )
            .then(() => {
              setSuccessful(false);
            })
            .catch(() => {
              setSuccessful(false);
            });
          //setSuccessful(false);
          // navigate("/reapprovisionnement");
          //window.location.reload();
        })
        .catch(() => {
          setSuccessful(false);
          
          if(status ===401){
            
            navigate("/");
            Logout()
          }

          setOtp("");
        });
    }
  };

  const showModal = () => {
    if (montant === "") {
      return setIsModalOpen(false);
    }
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(clearMessage()); // clear message when changing location
    setOtp("");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(clearMessage()); // clear message when changing location
    setOtp("");
  };

  function handleCloseModalhomeModal() {
    setModalhomeModal(false);
    dispatch(clearMessage()); // clear message when changing location
    window.location.reload();
  }

  function handleNavigate() {
    navigate("/home");
  }

  

  if (!isLoggedIn) {
    return <Navigate replace to="/" />;
  } else {
    return (
      <>
        <Row className="bg-bluegray_50 font-montserrat items-center mx-[auto] lg:pr-[21px] xl:pr-[27px] 2xl:pr-[30px] 3xl:pr-[36px] w-[100%]">
          <Sidebar />
          <Stack className="3xl:h-[1050px] lg:h-[780px] xl:h-[900px] 2xl:h-[1040px] lg:ml-[20px] lg:ml-[21px] xl:ml-[27px] 2xl:ml-[30px] 3xl:ml-[36px] w-[80%]">
            <Sidebarleft />
            <Column className="absolute bg-gray_50 items-center h-[60%] justify-start left-[0] lg:py-[22px] xl:py-[27px] 2xl:py-[31px] 3xl:py-[37px] rounded-radius16 h-[55%] w-[78%]">
              <Column className="items-center justify-start  lg:mb-[266px] xl:mb-[333px] 2xl:mb-[375px] 3xl:mb-[450px] w-[100%]">
                <Row className="items-center w-[92%]">
                  <Button
                    onClick={handleNavigate}
                    className="flex items-center justify-center rounded-radius50 test_1200x630waTwo"
                    variant="icbFillGray201"
                  >
                    <Img
                      src="images/img_arrowleft.svg"
                      className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                      alt="arrowleft"
                    />
                  </Button>
                  <Text
                    className="font-montserrat leading-[144.02%] lg:ml-[21px] xl:ml-[27px] 2xl:ml-[30px] 3xl:ml-[36px] mt-[1px] text-black_900 w-[31%]"
                    as="h2"
                    variant="h2"
                  >
                    Appel de Fonds
                  </Text>
                  <Img
                    src={calssSoled ? "" : "images/img_computer.svg"}
                    className="lg:h-[20px] xl:h-[38px] 2xl:h-[30px] 3xl:h-[51px] lg:ml-[117px] xl:ml-[146px] 2xl:ml-[164px] 3xl:ml-[197px] lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
                    alt=""
                  />
                  <Text
                    className="font-inter lg:ml-[10px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[18px] text-black_900 w-[auto]"
                    as="h1"
                    variant="h1"
                  >
                    <span className="text-gray_600 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                      Solde{" "}
                    </span>
                    <span
                      className={
                        calssSoled
                          ? "text-bluegray_600 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]"
                          : "text-amber_A700 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]"
                      }
                    >
                      {new Intl.NumberFormat("de-DE").format(balance.solde)}{" "}
                      Fcfa
                    </span>
                  </Text>
                  {/* <Img
                    src="images/img_ueyeslash.svg"
                    className="lg:ml-[10px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[18px] mobile"
                    alt="ueyeslash"
                  /> */}
                </Row>
                <Line className="bg-black_900_19 h-[1px] lg:mt-[12px] xl:mt-[16px] 2xl:mt-[18px] 3xl:mt-[21px] w-[100%]" />
                <Column className="justify-start lg:mt-[15px] xl:mt-[32px] 2xl:mt-[36px] 3xl:mt-[43px] w-[93%]">
                  <Text
                    className="text-black_900_cc w-[auto]"
                    as="h2"
                    variant="h2"
                  >
                    Montant demandé au superviseur
                  </Text>
                  <Text className="fcfaCounter_Two" as="h4" variant="h4">
                    Saisissez le montant
                  </Text>
                  <Input
                    className="placeholder:text-black_900_b2 GroupFour"
                    wrapClassName="2xl:mt-[15px] 3xl:mt-[18px] lg:mt-[10px] w-[100%] xl:mt-[13px]"
                    type="number"
                    //placeholder="780.000"
                    value={montant}
                    onChange={onChangeMontant}
                  ></Input>
                  <Button
                    className="font-semibold lg:mt-[101px] xl:mt-[40px] 2xl:mt-[142px] 3xl:mt-[171px] lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px] text-center w-[100%]"
                    shape="RoundedBorder8"
                    size="md"
                    onClick={showModal}
                    disabled={!isdisabled ? "true" : ""}
                  >
                    Envoyer la demande
                  </Button>
                </Column>
                <br />
                <br />
                <br />
                <Text className="columnhistoriquedes" as="h3" variant="h3">
                  Historique Appel Fonds
                </Text>
                <br />
                <ListAppel
                  userId={currentUser ? currentUser.response.user.id : null}
                />
              </Column>
            </Column>
          </Stack>
        </Row>

        <Modal
          title=" Appel de Fonds"
          footer={null}
          open={isModalOpen}
          onOk={handleOk}
          okText="Confirmer"
          cancelText="Annuler"
          onCancel={handleCancel}
        >
          {message && (
           <div className="form-group">
             <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
               {message}
             </div>
           </div>
         )}
            <div class=" p-2 text-center">
          <Img
              src="images/coolupay.png"
             
              alt="logobbsOne"
            />
           <h2> Montant demandé au superviseur  :</h2>
            <Text className="Numrodubnf" as="h7" variant="h7">
             {montant} Fcfa
            </Text>
            <h4>veuillez entrer votre code OTP pour envoyer la demande</h4>
            <br/>
            <div className="absolute content-center items-center  right-[-10] top-[20] ">
          <OTPInput
           inputStyles={{width: "20%",
                         height: "30px",
                               textAlign: "center",
                              fontSize: "14px",
                               fontWeight: "bold"}}
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={4}
              otpType="number" disabled={false}  />
              <div></div>
          </div>
          </div>
            <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
              <Button
                className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                shape="RoundedBorder8"
                variant="FillBluegray100"
                onClick={handleOk}
              >
                Annuler
              </Button>
              <Button
                className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                shape="RoundedBorder8"
                onClick={handleSubmit}
                disabled={successful}>
                {successful && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                Confirmer
              </Button>
            </Row>
        </Modal>

        {isOpenModalhomeModal ? (
          <MMencoursModal
            isOpen={isOpenModalhomeModal}
            onRequestClose={handleCloseModalhomeModal}
            successful={successful}
            status={status}
            message={message}
          />
        ) : null}
      </>
    );
  }
};

export default AppelFondPage;
