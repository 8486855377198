import React from "react";
import PropTypes from "prop-types";

const shapes = {
  icbRoundedBorder8: "rounded-radius8",
  icbCircleBorder16: "rounded-radius16",
  RoundedBorder8: "rounded-radius8",
  RoundedBorder5: "rounded-radius5",
};
const variants = {
  icbFillWhiteA701: "bg-white_A701",
  icbFillGray200: "bg-gray_200",
  icbFillCyan500: "bg-cyan_500",
  icbFillWhiteA700: "bg-white_A700",
  icbFillGray100: "bg-gray_100",
  icbFillBlack900: "bg-black_900",
  icbFillGray201: "bg-gray_201",
  FillIndigoA700: "bg-indigo_A700 text-white_A701",
  FillLightgreen60059: "bg-light_green_600_59 text-light_green_800_b3",
  FillRedA70059: "bg-red_A700_59 text-red_900_99",
  FillAmberA40059: "bg-amber_A400_59 text-lime_900_b2",
  OutlineBluegray600:
    "bg-bluegray_600_19 border border-bluegray_600 border-solid text-bluegray_600",
  OutlineBlack9007f:
    "border border-black_900_7f border-solid text-black_900_7f",
  FillBluegray100: "bg-bluegray_100 text-black_900_7f",
};
const sizes = {
  smIcn: "lg:p-[4px] 2xl:p-[5px] xl:p-[5px] p-[6px]",
  mdIcn: "3xl:p-[10px] lg:p-[6px] xl:p-[7px] 2xl:p-[8px] p-[9px]",
  sm: "lg:p-[5px] xl:p-[6px] 2xl:p-[7px] p-[8px] 3xl:p-[9px]",
  md: "lg:p-[15px] xl:p-[19px] 2xl:p-[21px] p-[23px] 3xl:p-[26px]",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant,
  size,
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${shapes[shape] || ""} ${
        variants[variant] || ""
      } ${sizes[size] || ""} common-button `}
     
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shape: PropTypes.oneOf([
    "icbRoundedBorder8",
    "icbCircleBorder16",
    "RoundedBorder8",
    "RoundedBorder5",
  ]),
  variant: PropTypes.oneOf([
    "icbFillWhiteA701",
    "icbFillGray200",
    "icbFillCyan500",
    "icbFillWhiteA700",
    "icbFillGray100",
    "icbFillBlack900",
    "icbFillGray201",
    "FillIndigoA700",
    "FillLightgreen60059",
    "FillRedA70059",
    "FillAmberA40059",
    "OutlineBluegray600",
    "OutlineBlack9007f",
    "FillBluegray100",
  ]),
  size: PropTypes.oneOf(["smIcn", "mdIcn", "sm", "md"]),
};
Button.defaultProps = {
  className: "",
  shape: "icbRoundedBorder8",
  variant: "FillIndigoA700",
  size: "smIcn",
};

export { Button };
