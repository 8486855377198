
import { Button } from 'components/Button';
import React, { useState } from 'react';




export default function StatusActions(props) {

    const status = props?.record?.status
    const [loading] = useState(false)
   
   
    if (loading) return <Button type="primary" shape="circle" loading />
    if (status === 'INITIATED') return (
        <Button variant="FillAmberA40059">En cours </Button>
       
    )

    if (status === 'FAILED') return (
        <Button variant="FillRedA70059"> Echec </Button>
    )


    if (status === 'COMPLETED') return (
        <Button variant="FillLightgreen60059"> Succès </Button>
    )

    return (
       <Button variant="FillRedA70059"> Echec </Button>
    )
}