import React from "react";
import Reapprovisionnement from "./pages/Reapprovisionnement";

import OMpay from "./pages/OMpay";
import Freepay from "./pages/Freepay";
import Wavepay from "./pages/Wavepay";
import OMretrait from "./pages/OMretrait";
import Freeretrait from "./pages/Freeretrait";
import Waveretrait from "./pages/Waveretrait";
import MM from "./pages/MM";
import MWave from "./pages/MWave"
import Facturiers from "./pages/Facturiers";
import Assurances from "./pages/Assurances";
import From from "./pages/From";
import FromSenEau from "./pages/FromSenEau"
import FromFact from "./pages/FromFact"
import FromAirtimes from "pages/FromAirtimes";
import Home from "./pages/Home";
import Airtimes from "./pages/Airtimes";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import NotFound from "./pages/NotFound";
import TI from "./pages/TI";
import SB from "./pages/SB";
import Abonnement from "./pages/Abonnement";

import Login from "./pages/Login";

import ProfilePage from "./pages/Profile";
import AppelFondPage from "./pages/AppelFond";

const ProjectRoutes = () => {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/facturiers" element={<Facturiers />} />
        <Route path="/assurances"   element={<Assurances />}/>
        <Route path="/transfertIn" element={<TI/>} />
        <Route path="/sb" element={<SB/>} />
        <Route path="/airtime" element={<Airtimes/>}/>
        <Route path="/abonnement" element={<Abonnement/>}/>
        <Route path="/reapprovisionnement" element={<Reapprovisionnement />} />
        <Route path="/profile" element={<ProfilePage />} />
       
        <Route path="/appelfand" element={<AppelFondPage/>}/>
       
        <Route path="/om" element={<MM 
                                       mm="Orange Money" 
                                       img="images/img_group266.png"
                                       url_retrait="/omretrait"
                                       url_pay="/ompay"
                                    />
                                  }/>
        <Route path="/wave" element={<MWave 
                                       mm="Wave" 
                                       img="images/img_group265.png"
                                       url_retrait="/waveretrait"
                                       url_pay="/wavepay"
                                    />
                                  } />
        <Route path="/free" element={<MM 
                                       mm="Free Money" 
                                       img="images/img_group253.png"
                                       url_retrait="/freeretrait"
                                       url_pay="/freepay"
                                    />
                                  } />

        {/* <Route path="/wizall" element={<MM 
                                       mm="Wizall" 
                                       img="images/img_unnamed13.png"
                                    />
                                  } /> */}

        {/* route pay and etrait*/}
        <Route path="/omretrait" element={<OMretrait />} />
        <Route path="/ompay" element={<OMpay/> }/>
        <Route path="/freeretrait" element={<Freeretrait />} />
        <Route path="/freepay" element={<Freepay/> }/>
        <Route path="/waveretrait" element={<Waveretrait />} />
        <Route path="/wavepay" element={<Wavepay/> }/>
        
        
         {/* route From */}
        <Route path="/senelec" element={<From
                                          type="Senelec" 
                                          operation="senelec"
                                          
                                          img="images/img_logosenelec1.png"
                                          num="Facture"
                                          url_r="/facturiers"
                                        
                                        />} />
        <Route path="/rapido" element={<FromFact
                                          type="Rapido" 
                                          operation="reload"
                                          service="Rapido"
                                          img="images/img_group47.png"
                                          num="de carte"
                                          url_r="/facturiers"
                                        
                                        />} />
         <Route path="/Woyofal" element={<From
                                          type="Woyofal" 
                                          operation="woyofal"
                                          
                                          img="images/img_logowoyofal01.png"
                                          num="Compteur"
                                          url_r="/facturiers"
                                        
                                        />} />
          <Route path="/SenEau" element={<FromSenEau
                                          type="SenEau" 
                                          operation="payment"
                                          service="SenEau"
                                          img="images/img_getfile2_10X35.png"
                                          num="Compteur"
                                          url_r="/facturiers"
                                        
                                        />} />
        <Route path="/airtimefree" element={<FromAirtimes
                                          type="Free Mobile" 
                                          img="images/Free_mobile_2011.svg"
                                          operation="reload"
                                          service="Free"
                                          url_r="/airtime"
                                        
                                        
                                        />} />
        <Route path="/airtimeorange" element={<FromAirtimes
                            type="Orange " 
                            img="images/orange_logo.svg"
                            operation="reload"
                            service="Orange"
                            url_r="/airtime"
                          
                          
                          />} />
         <Route path="/airtimeexpresso" element={<FromAirtimes
                            type="Expresso" 
                            img="images/logo-expresso.png"
                            operation="reload"
                            service="Expresso"
                            url_r="/airtime"
                           
                          
                          />} />

        
      </Routes>
    </Router>
  );
};

export default ProjectRoutes;
