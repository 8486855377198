import React,{useState} from 'react';
import { Form, Modal, DatePicker } from 'antd';
//import moment from 'moment';
import { Row,Button,} from "./../../components";
import exportFromJSON from 'export-from-json'
import UserService from "../../services/user-service";
const Rangedate = (props) => {
  const { RangePicker } = DatePicker;
  const  [startDate, setStartDate]  = React.useState ( "" );
  const  [endDate, setEndDate]  = React.useState ( "" );
  const [form] = Form.useForm();
  //const modalVisible = true;
  const DateTimeFormat = 'YYYY-MM-DD';
  const [isLoggedIn, setLoggedIn] = React.useState(true);
  const [content, setContent] = useState("");
   
  // useEffect(() => {
    
  //   if( endDate !==null ){
  //         UserService.rearch(props.userId,startDate,endDate).then(
  //           (response) => {
  //             setContent(response.data.transaction);
  //             setLoggedIn(false)
  //           },
  //           (error) => {
  //             // eslint-disable-next-line
  //             const _content =
  //               (error.response && error.response.data) ||
  //               error.message ||
  //               error.toString();

  //           // setContent(_content);
  //           }
  //         );

  //   }

  // }, []);

     
   

  const onChange =(value, dateString)=> {
   // console.log('Selected Time: ', value);
   // console.log('Formatted Selected Time: ', dateString)
    setStartDate(dateString[0])
    setEndDate(dateString[1])
   }

 

   const isEnabled = (startDate.length > 0 && endDate.length > 0 )
   const handleClick = () => {
    UserService.rearch(props.userId,startDate,endDate).then(
      (response) => {
        setContent(response.data.transaction);
        setLoggedIn(true)
      },
      (error) => {
        // eslint-disable-next-line
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

      // setContent(_content);
      }
    );

    
   if(content){
    //console.log("data : ",content);
    const data = content
    const newdate =  new Date()
    const fileName = 'listMM'+newdate
    const exportType =  exportFromJSON.types.csv
    exportFromJSON({ data, fileName, exportType })

   }
    
     
  };
  


   //console.log("startDate : ",startDate);
   //console.log("endDate : ",endDate);
 

  return (
    <Modal  {...props}  footer={null} >
      <Form
        form={form}
      >
        <Form.Item
          name="range_picker"
          label="Date range"
          rules={[{ required: true, message: 'Please select date' }]}
        >
          <RangePicker format={DateTimeFormat}  onChange={onChange} />
        </Form.Item>
      </Form>
      <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
              <Button
                className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                shape="RoundedBorder8"
                variant="FillBluegray100"
                onClick={props.onCancel}
              >
                Annuler
              </Button>
              <Button
                className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                shape="RoundedBorder8"
                onClick={handleClick}
                disabled={!isEnabled ? "true" : ""}
                // disabled={isLoggedIn}
                >
                {/* {isLoggedIn && (
                  <span className="spinner-border spinner-border-sm"></span>
                )} */}
                Confirmer
              </Button>
            </Row>
    </Modal>
  );
  
  
  
};
export {Rangedate}