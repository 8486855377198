//import Dropdown  from "../../components/Dropdown";
import React,{useCallback} from "react";


import {
    Row,
    Column,
    Img,
    Text,
 
    Stack,
    Button,

  } from "./../../components";
  import { NavLink } from "react-router-dom";
  import { useNavigate } from "react-router-dom";
  import { useDispatch } from "react-redux";
import {logout} from "../../actions/auth"
import { Dropdown } from "antd";
const SidebarMm = (props) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
 
  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const items = [
    {
      key: "1",
      label: (
        <Text className="" as="h4" variant="h4">
                       <strong>Caissier</strong>   
        </Text>
      ),
    },
    {
      key: "2",
      label: (
        <NavLink
           to = '/profile'
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent " 
           
          }
        
        >
            Paramètres
        </NavLink>
      ),
    },
    {
      key: "3",
      label: (
        <NavLink
          to = '/'
          onClick={logOut}
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent " 
            
          }
          
        >
          Déconnexion
        </NavLink>
      ),
    },
  ];
  
  function handleNavigateSenelec() {
    navigate("/senelec");  
  }
  function handleNavigateWave() {
    navigate("/wave");  
  }
  function handleNavigateOM() {
    navigate("/om");  
  }
  
  return (
    <>
      <aside className={props.className}>
        <div className="">
        <Column className="absolute items-center justify-start right-[0] top-[0] w-[19%]">
            <Column className="items-center justify-start w-[100%]">
              <Row className="bg-white_A700 items-center justify-between lg:p-[10px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] rounded-radius16 w-[100%]">
                {/* <Button
                  className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
                  size="mdIcn"
                  variant="icbFillGray200"
                >
                  <Img
                    src="images/img_settings.svg"
                    className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                    alt="settings"
                  />
                </Button> */}
                <Dropdown
                menu={{
                  items,
                }}
                placement="bottom"
                arrow={{
                  pointAtCenter: true,
                }}
              >
               <button
        className={
          "flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
        }
        size="mdIcn"
        variant="icbFillGray200"
        type="button"
      >
        <Img
          src="images/img_settings.svg"
          className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
          alt="settings"
        />
      </button>
              </Dropdown>
                <Text
                  className="font-semibold text-black_900 w-[auto]"
                  as="h4"
                  variant="h4"
                >
                  {props.username}
                </Text>
                <Img
                  src="images/img_ellipse1.png"
                  className="lg:h-[26px] xl:h-[33px] 2xl:h-[37px] 3xl:h-[44px] rounded-radius50 lg:w-[25px] xl:w-[32px] 2xl:w-[36px] 3xl:w-[43px]"
                  alt="EllipseOne"
                />
              </Row>
             
              
            </Column>
            
            <Column className="bg-white_A700 justify-start lg:mt-[29px] xl:mt-[37px] 2xl:mt-[41px] 3xl:mt-[50px] lg:p-[12px] xl:p-[15px] 2xl:p-[17px] 3xl:p-[20px] rounded-radius16 w-[100%]">
            
                <Column className="justify-start mb-[4px] lg:mt-[5px] xl:mt-[6px] 2xl:mt-[7px] 3xl:mt-[9px] w-[90%]">
                
                      <Row 
                        onClick={handleNavigateOM}
                        className="items-center w-[100%]">
                        <Button
                          className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
                          variant="icbFillWhiteA701"
                        >
                          <Img
                            src="images/img_group266.png"
                            className="flex items-center justify-center lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px]"
                            alt="GroupSixtyOne"
                          />
                        </Button>
                        <Text className="rowgroup266" as="h4" variant="h4">
                          Orange Money
                        </Text>
                      </Row>
                      <Row 
                          onClick={handleNavigateWave}
                        className="items-center xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] w-[57%]">
                        <Button
                          className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
                          variant="icbFillCyan500"
                        >
                          <Img
                            src="images/img_group265.png"
                            className="flex items-center justify-center"
                            alt="GroupSixty"
                          />
                        </Button>
                        <Text className="rowgroup266" as="h4" variant="h4">
                          Wave
                        </Text>
                      </Row>
                      <Row className="items-center xl:mt-[11px] 2xl:mt-[13px] 3xl:mt-[16px] lg:mt-[9px] w-[95%]">
                        <Stack className="bg-black_900 test_27332168912906_One">
                          <Img
                            src="images/img_westernunionl.png"
                            className="WesternUnionL"
                            alt="WesternUnionL"
                          />
                        </Stack>
                        <Text className="rowgroup266" as="h4" variant="h4">
                          Wester Union
                        </Text>
                      </Row>
                      <Row 
                          onClick={handleNavigateSenelec}
                          className="items-center xl:mt-[11px] 2xl:mt-[13px] 3xl:mt-[16px] lg:mt-[9px] w-[67%]">
                        <Img
                          src="images/img_logosenelec1.png"
                          className="logosenelecOne"
                          alt="logosenelecOne"
                        />
                        <Text className="rowgroup266" as="h4" variant="h4">
                          Senelec
                        </Text>
                      </Row>
                      <Row className="items-center xl:mt-[11px] 2xl:mt-[13px] 3xl:mt-[16px] lg:mt-[9px] w-[68%]">
                        <Stack className="bg-white_A701 px-[2px] test_27332168912906_One">
                          <Img
                            src="images/img_getfile2.png"
                            className="GetFileTwo"
                            alt="GetFileThree"
                          />
                        </Stack>
                        <Text className="rowgroup266" as="h4" variant="h4">
                          Sen eau
                        </Text>
                      </Row>
                </Column>
            </Column>
         
         </Column>
          
          
         <Text  className="Frquemmentuti"  as="h4" variant="h4">
            Fréquemment utilisé
          </Text>
          <Text className="Transactionsr" as="h4" variant="h4">
            Transactions récentes
          </Text> 
          
          {/* <Column className="absolute bottom-[8%]  lg:h-[238px] xl:h-[298px] 2xl:h-[335px] 3xl:h-[402px] right-[0]   right-0 h-45   w-[100%]"> */}
          <NavLink
           to = '/reapprovisionnement'>
          <Button className="2xl:mr-[8px] 3xl:mr-[10px] bottom-[4%]  absolute flex items-center justify-center lg:mr-[6px] right-0 text-center w-[18%] xl:mr-[7px]"
              leftIcon={
                <Img
                  src="images/img_camera.svg"
                  className="text-center right-[1%] absolute lg:w-[16px] lg:h-[17px] lg:mr-[8px] xl:w-[20px] xl:h-[21px] xl:mr-[10px] 2xl:w-[22px] 2xl:h-[23px] 2xl:mr-[11px] 3xl:w-[27px] 3xl:h-[28px] 3xl:mr-[13px]"
                  alt="camera"
                />
              }
              shape="RoundedBorder8"
              size="md"
              
            >
              <div  className="bg-transparent bottom-[0] font-bold right-[0] h-[17px] xl:text-[10px] 2xl:text-[11px] 3xl:text-[13px] lg:text-[8px]">
                Réapprovisionner 
              </div>
          </Button>
          </NavLink>
         
         
        </div>
      </aside>
    </>
  );
};

export default SidebarMm;
