import {
    VALIDE_SUCCESS,
    VALIDE_FAIL,
    SET_MESSAGE,
    SET_STATUS,
  } from "./types";
  
  import UserService from "../services/user-service";


  export const verifyPhoneOtp = (userId, otp) => (dispatch) => {
    return UserService.verifyPhoneOtp(userId, otp).then(
      (data) => {
        dispatch({
          type: VALIDE_SUCCESS,
          payload: { data },
        });
        
        dispatch({
          type: SET_STATUS,
          payload: data.data.status,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          const status = error.response.status
        dispatch({
          type: VALIDE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        dispatch({
          type: SET_STATUS,
          payload:status,
        });
  
        return Promise.reject();
      }
    );
  };


  export const updateProfile = (userId,firstName,lastName,email,phone) => (dispatch) => {
    return UserService.updateProfile(userId,firstName,lastName,email,phone).then(
      (response) => {
        dispatch({
          type: VALIDE_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: VALIDE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const updatePassword = (userId,password,newPassword) => (dispatch) => {
    return UserService.updatePassword(userId,password,newPassword).then(
      (response) => {
        dispatch({
          type: VALIDE_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: VALIDE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

 
  
 