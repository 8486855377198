import {
    VALIDE_SUCCESS,
    VALIDE_FAIL,
    SET_MESSAGE,
    SET_STATUS,
  } from "./types";
  
//import OMService from "../services/om-service";
import GTUService from "../services/gtu-fc-service"


export const Recharger = (number,phone,operation, amount,userId) => (dispatch) => {
    return GTUService.transaction(number,phone,operation, amount,userId).then(
      (response) => {
        dispatch({
          type: VALIDE_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
        dispatch({
          type: SET_STATUS,
          payload: response.data.status,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          const status = error.response.status
  
        dispatch({
          type: VALIDE_FAIL,
        });
        
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        dispatch({
          type: SET_STATUS,
          payload:status,
        });
  
        return Promise.reject();
      }
    );
  };

  export const RechargerFact = (number,phone,service,operation, amount,userId) => (dispatch) => {
    return GTUService.transactionSenEau(number,phone,service,operation, amount,userId).then(
      (response) => {
        dispatch({
          type: VALIDE_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
        dispatch({
          type: SET_STATUS,
          payload: response.data.status,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          const status = error.response.status
  
        dispatch({
          type: VALIDE_FAIL,
        });
        
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        dispatch({
          type: SET_STATUS,
          payload:status,
        });
  
        return Promise.reject();
      }
    );
  };


  