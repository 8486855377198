import {Space} from 'antd';
import { Button,Img } from 'components/';
import React, { useState } from 'react';




export default function ServiceActions(props) {

    const service = props?.record?.service
    const [loading] = useState(false)
    
    if (loading) return <Button type="primary" shape="circle" loading />
    if (service === 'OrangeMoney') return (
        <Button
            className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
            variant="icbFillGray100"
        >
            <Img
            src= "images/img_group266.png"
            className="flex items-center justify-center"
            alt="GroupFortyFive"
            />
        </Button>
       
    )

    if (service === 'Wave') return (
        <Button
            className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
            variant="icbFillGray100"
        >
            <Img
            src= "images/img_group265.png"
            className="flex items-center justify-center"
            alt="GroupFortyFive"
            />
        </Button>
    )
   
    return (
        <Space size='middle' >
            
        </Space>
    )

}