import React from "react";
import ModalProvider from "react-modal";
import { Preview, print } from 'react-html2pdf';
import { Column, Text, Img, Button } from "./../../components";
import {FcPrint} from "react-icons/fc";
const moment = require('moment');
const ModalhomeModal = (props) => {
  
  //()=>print('a', 'jsx-template')
  return (
    <>
     <ModalProvider
        //appElement={document.getElementById("root")}
        className="m-[auto]   items-center  w-[40%] "
        overlayClassName="bg-black_900_60 fixed flex h-[100%] inset-y-[0] w-[100%]"
        {...props}
      >
      
          <Column className="bg-gray_102 items-center justify-start lg:p-[26px] xl:p-[36px] 2xl:p-[36px] 3xl:p-[43px] rounded-radius16 w-[100%]">
           
              <Img
              src="images/img_close.svg"
              className="common-pointer test_1200x630waTwo"
              onClick={props.onRequestClose}
              alt="close"
            />
           
            <h2 >
            Details du transaction
            </h2>
            <br/>
            <div  id={'jsx-templat'} >
                <Text
                  className="font-semibold text-black_900_cc w-[auto]"
                  as="h4"
                  variant="h4"
                >
                  Type de transaction
                </Text>
                <Text className="Retrait" as="h4" variant="h4">
                  {props.record.type}
                </Text>
                <Text className="Numrodubnf" as="h4" variant="h4">
                  Numéro du bénéficiaire
                </Text>
                <Text className="test_221772346" as="h4" variant="h4">
                {props.record.phone}
                </Text>
                
                <Text className="Numrodubnf_One" as="h4" variant="h4">
                  Montant de la transaction
                </Text>
                <Text className="fcfaCounter" as="h4" variant="h4">
                {props.record.montant} Fcfa
                </Text>
                <Text
                  className="font-semibold lg:mt-[16px] xl:mt-[20px] 2xl:mt-[22px] 3xl:mt-[27px] text-black_900_cc w-[auto]"
                  as="h4"
                  variant="h4"
                >
                  Date
                </Text>
                <Text className="fcfaCounter" as="h4" variant="h4">
                
                {moment(props.record?.createdAt).format("DD-MM-YYYY HH:mm")}
                </Text>
                
                <Text className="Statut" as="h4" variant="h4">
                  Statut
                </Text>
                {props.record.status}
                </div> 
                <Button
                  className="font-semibold lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[100%]"
                  shape="RoundedBorder8"
                  size="md"
                  icon={<FcPrint size="25px" />} 
                  onClick={()=>print('recu', 'jsx-templat')}
                >
                  imprimer
                </Button>
              
                  
          </Column>
         
       
      </ModalProvider>
    </>
  );
};

export { ModalhomeModal };
