
import { SearchOutlined } from '@ant-design/icons';
import {DatePicker, Button, Input, Space, Table } from 'antd';
import React, { useRef,useState, useEffect } from "react";
import Highlighter from 'react-highlight-words';
//import { DataSource } from 'data/DataSource';
import StatusActions from './StatusActions';
import ButtonActions from './ButtonActions';
import ServiceActions from './ServiceActions';
import UserService from "../../services/user-service";

import {Rangedate} from '../../modals/Rangedate'

const moment = require('moment');

const { RangePicker } = DatePicker
//const data = DataSource;


const ListMobile = (props) => {
  let locale = {
    emptyText: 'pas des transactions traitement en cours',
  };
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [isLoggedIn, setLoggedIn] = React.useState(false);
  const [isOpenModalhomeModal, setModalhomeModal] = React.useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [content, setContent] = useState("");
  useEffect(() => {
    setLoggedIn(false)
    if(props.userId){

      UserService.getAllTransation(props.userId).then(
        (response) => {
          setContent(response.data.transaction);
          setLoggedIn(true)
        },
        (error) => {
          // eslint-disable-next-line
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
  
         // setContent(_content);
        }
      );
    }
   
  }, []);

  const handleChange = (pagination, filters, sorter) => {
   
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('')
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
          
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890FF' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#FFC069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  
  
//   const columns = [
//     {
//       title: 'Service',
//         dataIndex: 'transactions',
//         key: 'service',
//         width:'16%',
//         render: (text, record) => {
//           return <ServiceActions record={record} />
//       },
//       filters: [
//         {
//           text: 'Wave',
//           value: 'Wave',
//         },
//         {
//           text: 'Orange Money',
//           value: 'OrangeMoney',
//         },
//         {
//           text: 'Free Money',
//           value: 'FreeMoney',
//         },
//         {
//           text: 'Wizall',
//           value: 'Wizall',
//         },
        
        
//       ],
//       filteredValue: filteredInfo.service || null,
//       filterSearch: true,
//       onFilter: (value, record) => record.service.includes(value),
//       sorter: (a, b) => a.service.length - b.service.length,
//       sortOrder: sortedInfo.columnKey === 'service' ? sortedInfo.order : null,
//       ellipsis: true,
//     },
//     {
//       title: 'Numero',
//       dataIndex: 'numero',
//       key: 'numero',
     
//       render: (text, record) => {
//           return record?.numero
//       },
//       ...getColumnSearchProps('numero'),
//   },
//     {
//       title: 'Prix',
//       dataIndex: 'transactions',
//       key: 'prix',
//       render: (text, record) => {

//         return record?.montant
//     },
//     sorter: (a, b) => a.prix - b.prix,
//     sortOrder: sortedInfo.columnKey === 'prix' ? sortedInfo.order : null,
//     ellipsis: true,
//     },
//     {
//       title: 'Type',
//       dataIndex: 'transactions',
//       key: 'type',
//       render: (text, record) => {

//           return record?.type
//       },
//       filters: [
//         {
//           text: 'Cashin',
//           value: 'cashin',
//         },
//         {
//           text: 'Cashout',
//           value: 'cashout',
//         },
//         {
//           text: 'Payment',
//           value: 'payment',
//         }
//       ],
//       filteredValue: filteredInfo.type || null,
//       onFilter: (value, record) => record.type.includes(value),
//       sorter: (a, b) => a.type.length - b.type.length,
//       sortOrder: sortedInfo.columnKey === 'type' ? sortedInfo.order : null,
//      ellipsis: true,
      
     
//   },
//   {
//     title: 'Date',
//     dataIndex: 'transactions',
//     key: 'date',
  
//     render: (text, record) => {

//       return moment(record?.createdAt).format("DD-MM-YYYY HH:mm");
//        },
//       filters :[
//       {
//         text:<RangePicker
//                 value={dates || value}
//                 disabledDate={disabledDate}
//                 onCalendarChange={(val) => setDates(val)}
//                 onChange={(val) => setValue(val)}
//                 onOpenChange={onOpenChange}
//                 format={dateFormat}
//              />,
//             value: convertreturnValue1()
       
        
        
//       }
//     ],
//     filteredValue: filteredInfo.date || null,
//     onFilter: (value, record) => record.createdAt.includes(value),
//     sorter: (a, b) => a.createdAt.length - b.createdAt.length,
//     sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
//     ellipsis: true,
   
    
      
      
//   },
//   {
//     title: 'Status',
//     dataIndex: 'transactions',
//     key: 'status',
   
//     render: (text, record) => {
//       return <StatusActions record={record} />
    
//    },
//     filters: [
//       {
//         text: 'INITIATED',
//         value: 'INITIATED',
//       },
//       {
//         text: 'FAILED',
//         value: 'FAILED',
//       },
//       {
//         text: 'COMPLETED',
//         value: 'COMPLETED',
//       }
//     ],
//     filteredValue: filteredInfo.status || null,
//       filterSearch: true,
//       onFilter: (value, record) => record.status.includes(value),
//       ellipsis: true,
  
// },
//     {
//       title: '',
//       dataIndex: 'transactions',
//       key: 'Action',
//       width:'8%',
//       render: (text, record) => {
//         return <ButtonActions record={record} />
//     }}
//   ];

const columns = [
      {
      title: 'Service',
        dataIndex: 'transactions',
        key: 'service',
        width:'16%',
        ...getColumnSearchProps('service'),
        render: (text, record) => {
          return <ServiceActions record={record} />
      },

      //   filters: [
      //   {
      //     text: 'Wave',
      //     value: 'Wave',
      //   },
      //   {
      //     text: 'Orange Money',
      //     value: 'OrangeMoney',
      //   },
      //   {
      //     text: 'Free Money',
      //     value: 'FreeMoney',
      //   },
      //   {
      //     text: 'Wizall',
      //     value: 'Wizall',
      //   },
        
        
      // ],
      // filteredValue: filteredInfo.service || null,
      // filterSearch: true,
  },
 
  {
      title: 'Numero',
      dataIndex: 'numero',
      key: 'numero',
      ...getColumnSearchProps('numero'),
      render: (text, record) => {
          return record?.numero
      },
  },
      {
      title: 'Montant',
      dataIndex: 'transactions',
      key: 'prix',
      render: (text, record) => {

        return record?.montant
    },
    
    },
    {
      title: 'Type',
      dataIndex: 'transactions',
      key: 'type',
      render: (text, record) => {

          return record?.type
      },
     
      
     
  },

    {
    title: 'Date',
    dataIndex: 'transactions',
    key: 'date',
  
    render: (text, record) => {

      return moment(record?.createdAt).format("DD-MM-YYYY HH:mm:ss");
    }
     
  },
    {
    title: 'Status',
    dataIndex: 'transactions',
    key: 'status',
   
    render: (text, record) => {
      return <StatusActions record={record} />
    
   },
   
  
},
      {
      title: '',
      dataIndex: 'transactions',
      key: 'Action',
      width:'8%',
      render: (text, record) => {
        return <ButtonActions record={record} />
    }}
];

  const handleClick1 = () => {
    setModalhomeModal(true)

  }
  const handleOk = () => {
    setModalhomeModal(false);
   
    
  };

  const handleCancel = () => {
    setModalhomeModal(false);
    
  };

  
  return (
    <>
      <Space
        style={{
          marginBottom: 16,
        }}
      >
       <Button onClick={handleClick1}>Exporter Historique </Button>

       {isOpenModalhomeModal ? (
            <Rangedate
              open={isOpenModalhomeModal}
              onOk={handleOk} okText="Confirmer" cancelText="Annuler" onCancel={handleCancel}
              userId = {props.userId}
              
            />
          ) : null} 
        
        
      </Space> 
      
       
      <Table
          locale={locale}
          columns={columns} 
          dataSource={content} 
          pagination={{ pageSize: 6}} 
          loading={!isLoggedIn} 
          onChange={handleChange} 
       />
    </>
  );
};
export default ListMobile;