import React, {useState, useEffect } from "react";
import {Column,Text,Stack,} from "./../../components";
import { Puff   } from 'react-loading-icons'
import UserService from "../../services/user-service";
const Sidebarleft2 = (props) => {
    const [content, setContent] = useState("");
    
    useEffect(() => {
      if(props.userId){
        UserService.getAllTransationlimit(props.userId).then(
          (response) => {
          
            setContent(response.data.transaction);
          },
          (error) => {
            // eslint-disable-next-line
            const _content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
    
            setContent(null);
          }
        );
      }
      
    }, []);
    let obj1 = null
    let obj2 = null
    let obj3 = null
    let obj4 = null
    let obj5 = null
    if(content){
         obj1 = content[0];
         obj2 = content[1];
         obj3 = content[2];
         obj4 = content[3];
         obj5 = content[4];

    }
   
    
  return (
    <>
      <aside >
        <Stack className="absolute bottom-[8%] lg:h-[238px] xl:h-[298px] 2xl:h-[335px] 3xl:h-[402px] right-[0] w-[34%]">
              <Column className="absolute bg-white_A700 items-center justify-end xl:p-[11px] 2xl:p-[13px] 3xl:p-[16px] lg:p-[9px] right-[0] rounded-radius16 w-[56%]">
              {(() => {
                        
                        if(obj2){

                            if (`${(obj2.status)}` === 'INITIATED' ) {
                            return (
                                <Column className="bg-yellow_A700_4c border-solid border-yellow_A700 justify-end lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] lg:p-[5px] xl:p-[6px] 2xl:p-[7px] 3xl:p-[9px] rounded-radius8 w-[94%]">
                                  <Text className="Encours" as="h5" variant="h5">
                                    En cours 
                                  </Text>
                                  <Text className="RetraitOrange" as="h6" variant="h6">
                                    {(obj2.type)} {(obj2.service)} 
                                  </Text>
                                </Column>
                            )
                            } else if (`${(obj2.status)}` === 'COMPLETED') {
                            return (
                              <Column className="bg-light_green_200 border-light_green_800_b2 border-solid justify-end lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] lg:p-[5px] xl:p-[6px] 2xl:p-[7px] 3xl:p-[9px] rounded-radius8 w-[94%]">
                                <Text className="Succs" as="h5" variant="h5">
                                  Succès
                                </Text>
                                <Text className="DptOrangeMo" as="h6" variant="h6">
                                {(obj2.type)} {(obj2.service)} 
                                </Text>
                              </Column>
                            )
                            } else {
                            return (
                              
                                <Column className="bg-pink_100 border-red_A200 border-solid justify-end lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] lg:p-[5px] xl:p-[6px] 2xl:p-[7px] 3xl:p-[9px] rounded-radius8 w-[94%]">
                                  <Text className="Encours" as="h5" variant="h5">
                                    Echec
                                  </Text>
                                  <Text className="RetraitOrange" as="h6" variant="h6">
                                  {(obj2.type)} {(obj2.service)} 
                                  </Text>
                                </Column>
                            )
                            }
                          }else {return <Puff    stroke="#4444ee66" strokeOpacity = { .1 } strokeWidth={.2}/>}  
                })()}
                
              {(() => {
                      
                      if(obj3){

                          if (`${(obj3.status)}` === 'INITIATED' ) {
                          return (
                              <Column className="bg-yellow_A700_4c border-solid border-yellow_A700 justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] lg:p-[5px] xl:p-[6px] 2xl:p-[7px] 3xl:p-[9px] rounded-radius8 w-[94%]">
                                <Text className="Encours" as="h5" variant="h5">
                                  En cours 
                                </Text>
                                <Text className="RetraitOrange" as="h6" variant="h6">
                                  {(obj3.type)} {(obj3.service)} 
                                </Text>
                              </Column>
                          )
                          } else if (`${(obj3.status)}` === 'COMPLETED') {
                          return (
                            <Column className="bg-light_green_200 border-light_green_800_b2 border-solid justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] lg:p-[5px] xl:p-[6px] 2xl:p-[7px] 3xl:p-[9px] rounded-radius8 w-[94%]">
                              <Text className="Succs" as="h5" variant="h5">
                                Succès
                              </Text>
                              <Text className="DptOrangeMo" as="h6" variant="h6">
                              {(obj3.type)} {(obj3.service)} 
                              </Text>
                            </Column>
                          )
                          } else {
                          return (
                            
                              <Column className="bg-pink_100 border-red_A200 border-solid justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] lg:p-[5px] xl:p-[6px] 2xl:p-[7px] 3xl:p-[9px] rounded-radius8 w-[94%]">
                                <Text className="Encours" as="h5" variant="h5">
                                  Echec
                                </Text>
                                <Text className="RetraitOrange" as="h6" variant="h6">
                                {(obj3.type)} {(obj3.service)} 
                                </Text>
                              </Column>
                          )
                          }
                        }else {return <Puff    stroke="#4444ee66" strokeOpacity = { .1 } strokeWidth={.2}/>}  
              })()}

              {(() => { 
                 if(obj4){

                          if (`${(obj4.status)}` === 'INITIATED' ) {
                          return (
                              <Column className="bg-yellow_A700_4c border-solid border-yellow_A700 justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] lg:p-[5px] xl:p-[6px] 2xl:p-[7px] 3xl:p-[9px] rounded-radius8 w-[94%]">
                                <Text className="Encours" as="h5" variant="h5">
                                  En cours 
                                </Text>
                                <Text className="RetraitOrange" as="h6" variant="h6">
                                  {(obj4.type)} {(obj4.service)} 
                                </Text>
                              </Column>
                          )
                          } else if (`${(obj4.status)}` === 'COMPLETED') {
                          return (
                            <Column className="bg-light_green_200 border-light_green_800_b2 border-solid justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] lg:p-[5px] xl:p-[6px] 2xl:p-[7px] 3xl:p-[9px] rounded-radius8 w-[94%]">
                              <Text className="Succs" as="h5" variant="h5">
                                Succès
                              </Text>
                              <Text className="DptOrangeMo" as="h6" variant="h6">
                              {(obj4.type)} {(obj4.service)} 
                              </Text>
                            </Column>
                          )
                          } else {
                          return (
                            
                              <Column className="bg-pink_100 border-red_A200 border-solid justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] lg:p-[5px] xl:p-[6px] 2xl:p-[7px] 3xl:p-[9px] rounded-radius8 w-[94%]">
                                <Text className="Encours" as="h5" variant="h5">
                                  Echec
                                </Text>
                                <Text className="RetraitOrange" as="h6" variant="h6">
                                {(obj4.type)} {(obj4.service)} 
                                </Text>
                              </Column>
                          )
                          }
                        }else {return <Puff    stroke="#4444ee66" strokeOpacity = { .1 } strokeWidth={.2}/>}  
              })()}

              {(() => { 
                 if(obj5){

                          if (`${(obj5.status)}` === 'INITIATED' ) {
                          return (
                              <Column className="bg-yellow_A700_4c border-solid border-yellow_A700 justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] lg:p-[5px] xl:p-[6px] 2xl:p-[7px] 3xl:p-[9px] rounded-radius8 w-[94%]">
                                <Text className="Encours" as="h5" variant="h5">
                                  En cours 
                                </Text>
                                <Text className="RetraitOrange" as="h6" variant="h6">
                                  {(obj5.type)} {(obj5.service)} 
                                </Text>
                              </Column>
                          )
                          } else if (`${(obj5.status)}` === 'COMPLETED') {
                          return (
                            <Column className="bg-light_green_200 border-light_green_800_b2 border-solid justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] lg:p-[5px] xl:p-[6px] 2xl:p-[7px] 3xl:p-[9px] rounded-radius8 w-[94%]">
                              <Text className="Succs" as="h5" variant="h5">
                                Succès
                              </Text>
                              <Text className="DptOrangeMo" as="h6" variant="h6">
                              {(obj5.type)} {(obj5.service)} 
                              </Text>
                            </Column>
                          )
                          } else {
                          return (
                            
                              <Column className="bg-pink_100 border-red_A200 border-solid justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] lg:p-[5px] xl:p-[6px] 2xl:p-[7px] 3xl:p-[9px] rounded-radius8 w-[94%]">
                                <Text className="Encours" as="h5" variant="h5">
                                  Echec
                                </Text>
                                <Text className="RetraitOrange" as="h6" variant="h6">
                                {(obj5.type)} {(obj5.service)} 
                                </Text>
                              </Column>
                          )
                          }
                        }else {return <Puff    stroke="#4444ee66" strokeOpacity = { .1 } strokeWidth={.2}/>}  
              })()}

                
              </Column>
                
                <Column className="absolute items-end justify-start left-[0] lg:pb-[41px] xl:pb-[52px] 2xl:pb-[59px] 3xl:pb-[70px] lg:pl-[41px] xl:pl-[52px] 2xl:pl-[59px] 3xl:pl-[70px] top-[4%] w-[95%]">
                {(() => {
                        
                        if(obj1){

                            if (`${(obj1.status)}` === 'INITIATED' ) {
                            return (
                                <Column className="bg-yellow_A700_4c border-solid border-yellow_A700 justify-end lg:p-[5px] xl:p-[6px] 2xl:p-[7px] 3xl:p-[9px] rounded-radius8 w-[58%]">
                                  <Text className="Encours" as="h5" variant="h5">
                                    En cours 
                                  </Text>
                                  <Text className="RetraitOrange" as="h6" variant="h6">
                                    {(obj1.type)} {(obj1.service)} 
                                  </Text>
                                </Column>
                            )
                            } else if (`${(obj1.status)}` === 'COMPLETED') {
                            return (
                              <Column className="bg-light_green_200 border-light_green_800_b2 border-solid justify-end lg:p-[5px] xl:p-[6px] 2xl:p-[7px] 3xl:p-[9px] rounded-radius8 w-[58%]">
                                <Text className="Succs" as="h5" variant="h5">
                                  Succès
                                </Text>
                                <Text className="DptOrangeMo" as="h6" variant="h6">
                                {(obj1.type)} {(obj1.service)} 
                                </Text>
                              </Column>
                            )
                            } else {
                            return (
                              
                                <Column className="bg-pink_100 border-red_A200 border-solid justify-end lg:p-[2px] xl:p-[6px] 2xl:p-[7px] 3xl:p-[9px] rounded-radius8 w-[58%]">
                                  <Text className="Encours" as="h5" variant="h5">
                                    Echec
                                  </Text>
                                  <Text className="RetraitOrange" as="h6" variant="h6">
                                  {(obj1.type)} {(obj1.service)} 
                                  </Text>
                                </Column>
                            )
                            }
                          }else {return <Puff    stroke="#4444ee66" strokeOpacity = { .1 } strokeWidth={.2}/>}  
                })()}
   
                
               
              </Column>
    
              

            </Stack>
        
      </aside>
    </>
  );
};

export default Sidebarleft2;
