import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getBalance = (userId) => {
  //return axios.get(API_URL + "user/balance",{id});
  const data = JSON.stringify({
    "userId": userId,
  });
  

  const config = {
    method: 'post',
    url: API_URL + "user/balance",
    headers: authHeader(),
      
    data : data
  };
  return axios(config)
};

const rearch = (userId,startDate,endDate) => {
  // const response = JSON.parse(localStorage.getItem('user'));
  // const userId = response.response.user.id
  //return axios.get(API_URL + "user/balance",{id});
  const data = JSON.stringify({
    "userId": userId,
    "startDate" :startDate,
    "endDate" : endDate
  });
  

  const config = {
    method: 'post',
    url: API_URL + "transaction/search",
    headers: authHeader(),
      
    data : data
  };
  return  axios(config)
};

const getAllTransation = (userId) => {
  // const response = JSON.parse(localStorage.getItem('user'));
  // const userId = response.response.user.id
  //return axios.get(API_URL + "user/balance",{id});
  const data = JSON.stringify({
    "userId": userId,
  });
  

  const config = {
    method: 'post',
    url: API_URL + "transaction/show",
    headers: authHeader(),
      
    data : data
  };
  return axios(config)
};

const getAllTransationlimit = (userId) => {
  // const response = JSON.parse(localStorage.getItem('user'));
  // const userId = response.response.user.id
  //return axios.get(API_URL + "user/balance",{id});
  const data = JSON.stringify({
    "userId": userId,
  });
  

  const config = {
    method: 'post',
    url: API_URL + "transaction/limit",
    headers: authHeader(),
      
    data : data
  };
  return axios(config)
};
const getAllFact = (userId) => {
  // const response = JSON.parse(localStorage.getItem('user'));
  // const userId = response.response.user.id
  //return axios.get(API_URL + "user/balance",{id});
  const data = JSON.stringify({
    "userId": userId,
  });
  

  const config = {
    method: 'post',
    url: API_URL + "transaction/fact",
    headers: authHeader(),
      
    data : data
  };
  return axios(config)
};

const getAllAirtime = (userId) => {
  // const response = JSON.parse(localStorage.getItem('user'));
  // const userId = response.response.user.id
  //return axios.get(API_URL + "user/balance",{id});
  const data = JSON.stringify({
    "userId": userId,
  });
  

  const config = {
    method: 'post',
    url: API_URL + "transaction/airtimes",
    headers: authHeader(),
      
    data : data
  };
  return axios(config)
};

const generateOtp  = ()=>{

  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  
  const data = JSON.stringify({
    "userId": userId,
  });
  

  const config = {
    method: 'post',
    url: API_URL + "auth/generate/otp",
    headers: authHeader(),
      
    data : data
  };
  return axios(config)
}

const verifyPhoneOtp = (userId,otp)=>{

  var data = JSON.stringify({
    "userId": userId,
    "otp": otp
  });
  
  var config = {
    method: 'post',
    url: API_URL+'auth/verify/otp',
    headers:authHeader(),
    data : data
  };
  
   return axios(config)

}

const updateProfile = (userId,firstName,lastName,email,phone)=>{
  var data = JSON.stringify({
    "userId": userId,
    "firstName": firstName,
    "lastName": lastName,
    "email":email,
    "phone":phone
  });
  const config = {
    method: 'put',
    url: API_URL + "user/profile/update",
    headers: authHeader(),
      
    data : data
  };
  return axios(config)

}



const updatePassword = (userId,password,newPassword)=>{
  var data = JSON.stringify({
    "userId": userId,
    "password": password,
    "newPassword": newPassword
  });
  const config = {
    method: 'put',
    url: API_URL + "user/password/update",
    headers: authHeader(),
      
    data : data
  };
  return axios(config)

}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getBalance,getAllTransation,getAllAirtime,getAllTransationlimit,
    generateOtp,verifyPhoneOtp,
    getAllFact,
    updateProfile,
    updatePassword,rearch
  };