import React from "react";

import {useState} from "react"
import { Row, Stack, Button, Img, Text, Column, Line, Input } from "./../../components";
import Sidebar from "./../../components/Sidebar/Sidebar";
import { Navigate, useNavigate  } from 'react-router-dom';
import { useSelector } from "react-redux";

import { Modal } from 'antd';
import SidebarMm from "../../components/SiderbarMm";
const FreeretraitPage = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isLoggedIn } = useSelector(state => state.auth);
  const { user: currentUser } = useSelector((state) => state.auth);
  
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  function handleNavigate2() {
    navigate("/freepay");
  }
  function handleNavigate9() {
    navigate("/home");
  }
  function handleNavigate10() {
    navigate("/free");
  }
  
  if(!isLoggedIn){
    return <Navigate replace to="/"/>
  
  }else{
  return (
    <>
      <Row className="bg-bluegray_50 font-montserrat items-center mx-[auto] lg:pr-[21px] xl:pr-[27px] 2xl:pr-[30px] 3xl:pr-[36px] w-[100%]">
        <Sidebar  />
        <Stack className="3xl:h-[1050px] lg:h-[780px] xl:h-[900px] 2xl:h-[1040px] lg:ml-[21px] xl:ml-[27px] 2xl:ml-[30px] 3xl:ml-[36px] w-[80%]">
          {/* <Row className="absolute bg-white_A700 items-center justify-between lg:p-[10px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] right-[0] rounded-radius16 top-[0] w-[19%]">
            <Button
              className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
              size="mdIcn"
              variant="icbFillGray200"
            >
              <Img
                src="images/img_settings.svg"
                className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                alt="settings"
              />
            </Button>
            <Text
              className="font-semibold text-black_900 w-[auto]"
              as="h4"
              variant="h4"
            >
              Aminata
            </Text>
            <Img
              src="images/img_ellipse1.png"
              className="lg:h-[26px] xl:h-[33px] 2xl:h-[37px] 3xl:h-[44px] rounded-radius50 lg:w-[25px] xl:w-[32px] 2xl:w-[36px] 3xl:w-[43px]"
              alt="EllipseOne"
            />
          </Row>
          <Column className="absolute bg-white_A700 justify-start lg:p-[12px] xl:p-[15px] 2xl:p-[17px] 3xl:p-[20px] right-[0] rounded-radius16 top-[13%] w-[19%]">
            <Column className="justify-start mb-[4px] lg:mt-[5px] xl:mt-[6px] 2xl:mt-[7px] 3xl:mt-[9px] w-[90%]">
              <Row className="items-center w-[100%]">
                <Button
                  className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
                  variant="icbFillWhiteA701"
                >
                  <Img
                    src="images/img_group266.png"
                    className="flex items-center justify-center lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px]"
                    alt="Group180"
                  />
                </Button>
                <Text className="rowgroup266" as="h4" variant="h4">
                  Orange Money
                </Text>
              </Row>
              <Row className="items-center xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] w-[57%]">
                <Button
                  className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
                  variant="icbFillCyan500"
                >
                  <Img
                    src="images/img_group265.png"
                    className="flex items-center justify-center"
                    alt="Group179"
                  />
                </Button>
                <Text className="rowgroup266" as="h4" variant="h4">
                  Wave
                </Text>
              </Row>
              <Row className="items-center xl:mt-[11px] 2xl:mt-[13px] 3xl:mt-[16px] lg:mt-[9px] w-[95%]">
                <Stack className="bg-black_900 test_27332168912906_One">
                  <Img
                    src="images/img_westernunionl.png"
                    className="WesternUnionL"
                    alt="WesternUnionL"
                  />
                </Stack>
                <Text className="rowgroup266" as="h4" variant="h4">
                  Wester Union
                </Text>
              </Row>
              <Row className="items-center xl:mt-[11px] 2xl:mt-[13px] 3xl:mt-[16px] lg:mt-[9px] w-[67%]">
                <Img
                  src="images/img_logosenelec1.png"
                  className="logosenelecOne"
                  alt="logosenelecOne"
                />
                <Text className="rowgroup266" as="h4" variant="h4">
                  Senelec
                </Text>
              </Row>
              <Row className="items-center xl:mt-[11px] 2xl:mt-[13px] 3xl:mt-[16px] lg:mt-[9px] w-[68%]">
                <Stack className="bg-white_A701 px-[2px] test_27332168912906_One">
                  <Img
                    src="images/img_getfile2.png"
                    className="GetFileTwo"
                    alt="GetFileTwo"
                  />
                </Stack>
                <Text className="rowgroup266" as="h4" variant="h4">
                  Sen eau
                </Text>
              </Row>
            </Column>
          </Column>
          <Text className="Frquemmentuti" as="h4" variant="h4">
            Fréquemment utilisé
          </Text> */}
          <Text className="Transactionsr" as="h4" variant="h4">
            Transactions récentes
          </Text>
          <Stack className="absolute 3xl:h-[1050px] lg:h-[622px] xl:h-[778px] 2xl:h-[875px] w-[100%]">
            <Stack className="absolute bottom-[8%] lg:h-[238px] xl:h-[298px] 2xl:h-[335px] 3xl:h-[402px] right-[0] w-[34%]">
              <Column className="absolute bg-white_A700 items-center justify-end xl:p-[11px] 2xl:p-[13px] 3xl:p-[16px] lg:p-[9px] right-[0] rounded-radius16 w-[56%]">
                <Column className="bg-pink_100 border-red_A200 border-solid justify-end lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] lg:p-[5px] xl:p-[6px] 2xl:p-[7px] 3xl:p-[9px] rounded-radius8 w-[94%]">
                  <Text className="Echec" as="h5" variant="h5">
                    Echec
                  </Text>
                  <Text className="DptOrangeMo" as="h6" variant="h6">
                    Dépôt Orange Money
                  </Text>
                </Column>
                <Column className="bg-light_green_200 border-light_green_800_b2 border-solid justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] lg:p-[5px] xl:p-[6px] 2xl:p-[7px] 3xl:p-[9px] rounded-radius8 w-[94%]">
                  <Text className="Succs" as="h5" variant="h5">
                    Succès
                  </Text>
                  <Text className="DptOrangeMo" as="h6" variant="h6">
                    Dépôt Orange Money
                  </Text>
                </Column>
                <Column className="bg-light_green_200 border-light_green_800_b2 border-solid justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] lg:p-[5px] xl:p-[6px] 2xl:p-[7px] 3xl:p-[9px] rounded-radius8 w-[94%]">
                  <Text className="Succs" as="h5" variant="h5">
                    Succès
                  </Text>
                  <Text className="DptOrangeMo" as="h6" variant="h6">
                    Paiement Orange Money
                  </Text>
                </Column>
                <Column className="bg-yellow_A700_4c border-solid border-yellow_A700 justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] lg:p-[5px] xl:p-[6px] 2xl:p-[7px] 3xl:p-[9px] rounded-radius8 w-[94%]">
                  <Text className="Encours" as="h5" variant="h5">
                    En cours
                  </Text>
                  <Text className="RetraitOrange" as="h6" variant="h6">
                    Retrait Orange Money
                  </Text>
                </Column>
              </Column>
              <Column className="absolute items-end justify-start left-[0] lg:pb-[41px] xl:pb-[52px] 2xl:pb-[59px] 3xl:pb-[70px] lg:pl-[41px] xl:pl-[52px] 2xl:pl-[59px] 3xl:pl-[70px] top-[4%] w-[95%]">
                <Column className="bg-yellow_A700_4c border-solid border-yellow_A700 justify-end lg:p-[5px] xl:p-[6px] 2xl:p-[7px] 3xl:p-[9px] rounded-radius8 w-[58%]">
                  <Text className="Encours" as="h5" variant="h5">
                    En cours
                  </Text>
                  <Text className="RetraitOrange" as="h6" variant="h6">
                    Retrait Orange Money
                  </Text>
                </Column>
              </Column>
            </Stack>
    
            <Column className="absolute bg-gray_50 items-center justify-start left-[0] lg:py-[25px] xl:py-[32px] 2xl:py-[36px] 3xl:py-[43px] rounded-radius16 w-[78%]">
              <Column className="items-center justify-start lg:mb-[139px] xl:mb-[174px] 2xl:mb-[196px] 3xl:mb-[235px] w-[100%]">
                <Row className="items-start justify-between w-[92%]">
                  <Row className="items-start justify-between w-[24%]">
                    <Button
                      className="common-pointer flex items-center justify-center lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] 3xl:mt-[6px] rounded-radius50 test_1200x630waTwo"
                      onClick={handleNavigate9}
                      variant="icbFillGray201"
                    >
                      <Img
                        src="images/img_arrowleft.svg"
                        className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                        alt="arrowleft"
                      />
                    </Button>
                    <Button
                      className="flex lg:h-[30px] xl:h-[37px] 2xl:h-[41px] 3xl:h-[50px] items-center justify-center lg:w-[29px] xl:w-[36px] 2xl:w-[40px] 3xl:w-[49px]"
                      variant="icbFillWhiteA701"
                    >
                      <Img
                        src="images/img_group253.png"
                        className="flex items-center justify-center lg:h-[20px] xl:h-[25px] 2xl:h-[28px] 3xl:h-[34px]"
                        alt="Group168"
                      />
                    </Button>
                    <Text className="rowarrowleft" as="h3" variant="h3">
                      Free Money
                    </Text>
                  </Row>
                  <Row className="font-inter items-start justify-between mt-[1px] w-[36%]">
                    <Text
                      className="text-black_900 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      <span className="text-gray_600 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                        Solde{" "}
                      </span>
                      <span className="text-bluegray_600 font-montserrat font-bold lg:text-[21px] xl:text-[20px] 2xl:text-[30px] 3xl:text-[36px]">
                        100.000 Fcfa
                      </span>
                    </Text>
                    {/* <Img
                      src="images/img_ueyeslash.svg"
                      className="ueyeslash_One"
                      alt="ueyeslash"
                    /> */}
                  </Row>
                </Row>
                <Line className="bg-black_900_19 h-[1px] lg:mt-[19px] xl:mt-[24px] 2xl:mt-[27px] 3xl:mt-[33px] w-[100%]" />
                <Column className="justify-start lg:mt-[25px] xl:mt-[32px] 2xl:mt-[36px] 3xl:mt-[43px] w-[93%]">
                  <Text
                    className="text-black_900_cc w-[auto]"
                    as="h2"
                    variant="h2"
                  >
                    Type de transaction
                  </Text>
                  <Text className="Choisissezlat" as="h4" variant="h4">
                    Choisissez la transaction que vous vous voulez effectuer
                  </Text>
                  <Row className="items-center lg:mt-[11px] xl:mt-[14px] 2xl:mt-[16px] 3xl:mt-[19px] w-[71%]">
                    <Button
                      className="flex items-center justify-center text-center w-[30%]"
                      onClick={handleNavigate10}
                      leftIcon={
                        <Img
                          src="images/img_camera_3.svg"
                          className="text-center lg:w-[16px] lg:h-[17px] lg:mr-[8px] xl:w-[20px] xl:h-[21px] xl:mr-[10px] 2xl:w-[22px] 2xl:h-[23px] 2xl:mr-[11px] 3xl:w-[27px] 3xl:h-[28px] 3xl:mr-[13px]"
                          alt="camera"
                        />
                      }
                      shape="RoundedBorder8"
                      size="md"
                      variant="OutlineBlack9007f"
                    >
                      <div className="common-pointer bg-transparent font-semibold lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                        Dépôt
                      </div>
                    </Button>
                    <Button
                      className="2xl:ml-[30px] 3xl:ml-[36px] flex items-center justify-center lg:ml-[21px] text-center w-[30%] xl:ml-[27px]"
                      leftIcon={
                        <Img
                          src="images/img_camera_4.svg"
                          className="text-center lg:w-[16px] lg:h-[17px] lg:mr-[8px] xl:w-[20px] xl:h-[21px] xl:mr-[10px] 2xl:w-[22px] 2xl:h-[23px] 2xl:mr-[11px] 3xl:w-[27px] 3xl:h-[28px] 3xl:mr-[13px]"
                          alt="camera"
                        />
                      }
                      shape="RoundedBorder8"
                      size="md"
                      variant="OutlineBluegray600"
                    >
                      <div className="bg-transparent font-semibold lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                        Retrait
                      </div>
                    </Button>
                    <Button
                      className="2xl:ml-[30px] 3xl:ml-[36px] flex items-center justify-center lg:ml-[21px] text-center w-[30%] xl:ml-[27px]"
                      onClick={handleNavigate2}
                      leftIcon={
                        <Img
                          src="images/img_camera_2.svg"
                          className="text-center lg:w-[16px] lg:h-[17px] lg:mr-[8px] xl:w-[20px] xl:h-[21px] xl:mr-[10px] 2xl:w-[22px] 2xl:h-[23px] 2xl:mr-[11px] 3xl:w-[27px] 3xl:h-[28px] 3xl:mr-[13px]"
                          alt="camera"
                        />
                      }
                      shape="RoundedBorder8"
                      size="md"
                      variant="OutlineBlack9007f"
                    >
                      <div className="common-pointer bg-transparent font-semibold lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                        Paiement
                      </div>
                    </Button>
                  </Row>
                  <Text className="Numrodubnf2" as="h3" variant="h3">
                    Numéro du bénéficiaire
                  </Text>
                  <Input
                    className="placeholder:text-black_900_b2 GroupFour"
                    wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                    name="Group165"
                    placeholder="(+221) 77 234 67 84"
                  ></Input>
                  <Text
                    className="lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[41px] text-black_900_cc w-[auto]"
                    as="h3"
                    variant="h3"
                  >
                    Montant retiré{" "}
                  </Text>
                  <Input
                    className="placeholder:text-black_900_b2 GroupFour"
                    wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                    name="Group164"
                    placeholder="35.000"
                  ></Input>
                  <Button
                    className="common-pointer font-semibold lg:mt-[43px] xl:mt-[54px] 2xl:mt-[60px] 3xl:mt-[73px] lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px] text-center w-[100%]"
                    onClick={showModal}
                    shape="RoundedBorder8"
                    size="md"
                  >
                    Valider la transaction
                  </Button>
                </Column>
              </Column>
            </Column>
          </Stack>
          <SidebarMm username={currentUser ? (currentUser.response.user.username):null}/>
        </Stack>
      </Row>
      <Modal  title="Récapitulatif de la transaction" footer={null} open={isModalOpen} onOk={handleOk} okText="Confirmer" cancelText="Annuler" onCancel={handleCancel}>
      <Text
                className="font-semibold text-black_900_cc w-[auto]"
                as="h4"
                variant="h4"
              >
                Type de transaction
              </Text>
              <Text className="Retrait" as="h4" variant="h4">
                Retrait
              </Text>
              <Text className="Numrodubnf" as="h4" variant="h4">
                Numéro du bénéficiaire
              </Text>
              <Text className="test_221772346" as="h4" variant="h4">
                (+221) 77 234 67 84
              </Text>
              <Text className="Numrodubnf_One" as="h4" variant="h4">
                Montant de la transaction
              </Text>
              <Text className="fcfaCounter" as="h4" variant="h4">
                35.000 Fcfa
              </Text>
              <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
                <Button
                  className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                  
                  shape="RoundedBorder8"
                  
                  variant="FillBluegray100"
                  onClick={handleOk}
                >
                  Annuler
                </Button>
                <Button
                  className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                  shape="RoundedBorder8"
                 
                >
                  Confirmer
                </Button>
              </Row>
              
           
         
      </Modal>
    </>
  );
        }
};

export default FreeretraitPage;
