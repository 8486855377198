import React , { useEffect } from "react";

import {
  Row,
  
  Stack,
 
} from "../../components";


import Sidebar from "./../../components/Sidebar/Sidebar";

import Sidebarleft from "../../components/Sidebarleft";
import {  useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const SBPage = () => {
  
 
  const { user: currentUser } = useSelector((state) => state.auth);
 const { isLoggedIn } = useSelector(state => state.auth);


 if(!isLoggedIn){
  return <Navigate replace to="/"/>

}else{
  return (
    <>
      <Row className="bg-bluegray_50 font-montserrat items-center mx-[auto] lg:pr-[21px] xl:pr-[27px] 2xl:pr-[30px] 3xl:pr-[36px] w-[100%]">
      <Sidebar  />
        <Stack className="3xl:h-[1050px] lg:h-[780px] xl:h-[900px] 2xl:h-[1040px] lg:ml-[20px] xl:ml-[26px] 2xl:ml-[29px] 3xl:ml-[35px] w-[80%]">
        <Sidebarleft  username={currentUser ? (currentUser.response.user.username):null}/>
       
         
         {/* solde */}
        
        </Stack>
       
      </Row>
    </>
  );
}
};

export default SBPage;
