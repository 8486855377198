import axios from "axios";
import authHeader from "./auth-header";
const API_URL = process.env.REACT_APP_API_URL;


const create = (montant,username,senderId,receiverId)=>{
    const data = JSON.stringify({
        "amount": montant,
        "comment": "Demande de réapprovisionnement",
        "username": username,
        "userId": senderId,
        "receiverId":receiverId
      });
      
      var config = {
        method: 'post',
        url: API_URL+'reapprovisionnement',
        headers: authHeader(),
        data : data
      };
      
      return axios(config)

}

const getAllReap = (userId) => {
  
  //return axios.get(API_URL + "user/balance",{id});
  const data = JSON.stringify({
    "userId": userId,
  });
  

  const config = {
    method: 'post',
    url: API_URL + "reapprovisionnement/getReap",
    headers: authHeader(),
      
    data : data
  };
  return axios(config)
};


// eslint-disable-next-line import/no-anonymous-default-export
export default {
    create,getAllReap
  };