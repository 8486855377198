import {
    VALIDE_SUCCESS,
    VALIDE_FAIL,
    SET_MESSAGE,
    SET_STATUS,
  } from "./types";
  
  //import OMService from "../services/om-service";
  import GTUService from "../services/gtu-mm-service"


export const depot = (customerNum, amount,userId) => (dispatch) => {
    return GTUService.transaction(customerNum, "OrangeMoney","cashin",amount,userId).then(
      (response) => {
        dispatch({
          type: VALIDE_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
        dispatch({
          type: SET_STATUS,
          payload: response.data.status,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

          const status = error.response.status
  
        dispatch({
          type: VALIDE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        dispatch({
          type: SET_STATUS,
          payload:status,
        });
  
        return Promise.reject();
      }
    );
  };

export const retrait = (customerNum, amount,userId) => (dispatch) => {
    return GTUService.transaction(customerNum, "OrangeMoney","cashout",amount,userId).then(
      (response) => {
        dispatch({
          type: VALIDE_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
        dispatch({
          type: SET_STATUS,
          payload: response.data.status,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

          const status = error.response.status
  
        dispatch({
          type: VALIDE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        dispatch({
          type: SET_STATUS,
          payload:status,
        });
  
        return Promise.reject();
      }
    );
  };


export const pay = (customerNum, amount,userId) => (dispatch) => {
    return GTUService.transaction(customerNum, "OrangeMoney","payment",amount,userId).then(
      (response) => {
        dispatch({
          type: VALIDE_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
        dispatch({
          type: SET_STATUS,
          payload: response.data.status,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

          const status = error.response.status
           
  
        dispatch({
          type: VALIDE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        dispatch({
          type: SET_STATUS,
          payload:status,
        });
  
        return Promise.reject();
      }
    );
  };