import React from "react";

import { Column, Img, Row, Line } from "./../../../components";

import { NavLink, useNavigate } from "react-router-dom";

const Sidebar = (props) => {
  const activeLink =
    "text-bluegray_600  font-semibold xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] text-indigo_A700 w-[auto]";
  const normalLink = "rowfile md:text-center";
  // const normalLinkimg = "images/img_mobile_24X24.svg";
  const activeLinkimg = "images/img_mobile.svg";
  let navigate = useNavigate();

  function handleNavigate17() {
    navigate("/home");
  }

  return (
    <>
      <aside className={props.className}>
        <div className="">
          <Column className="bg-white_A700 justify-start lg:pr-[10px] xl:pr-[13px] 2xl:pr-[15px] 3xl:pr-[18px] lg:py-[10px] xl:py-[13px] 2xl:py-[15px] 3xl:py-[18px] shadow-bs w-[100%]">
            <Img
              src="images/img_logobbs1.png"
              className="lg:h-[70px] xl:h-[78px] 2xl:h-[100px] 3xl:h-[51px] lg:ml-[8px] xl:ml-[13px] 2xl:ml-[8px] 3xl:ml-[45px] lg:mt-[10px] xl:mt-[13px] 2xl:mt-[10px] 3xl:mt-[59px] w-[100%]"
              alt="logobbsOne"
              onClick={handleNavigate17}
            />
            <Row className="items-center 3xl:mt-[108px] lg:mt-[64px] xl:mt-[80px] 2xl:mt-[65px] w-[76%]">
              <Line className="bg-indigo_A700 lg:h-[42px] xl:h-[53px] 2xl:h-[60px] 3xl:h-[71px] rounded-bl-[0] rounded-br-[8px] rounded-tl-[0] rounded-tr-[8px] w-[6px]" />
              <Img
                src={activeLinkimg}
                className="lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] mobile"
                alt="mobile"
              />
              <NavLink
                to="/home"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                Mobile Money
              </NavLink>
            </Row>
            <Column className="justify-start lg:mb-[176px] xl:mb-[220px] 2xl:mb-[247px] 3xl:mb-[297px] lg:ml-[25px] xl:ml-[31px] 2xl:ml-[35px] 3xl:ml-[42px] lg:mt-[22px] xl:mt-[27px] 2xl:mt-[31px] 3xl:mt-[37px] w-[85%]">
              <Row className="common-pointer items-start w-[58%]">
                <Img src="images/img_file.svg" className="mobile" alt="file" />
                <NavLink
                  to="/facturiers"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                >
                  Facturiers
                </NavLink>
              </Row>
              <Row className="items-start lg:mt-[35px] xl:mt-[44px] 2xl:mt-[49px] 3xl:mt-[59px] w-[63%]">
                <Img
                  src="images/img_minimize.svg"
                  className="mobile"
                  alt="minimize"
                />
                <NavLink
                  to="/assurances"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                  as="h4"
                  variant="h4"
                >
                  Assurances
                </NavLink>
              </Row>
              <Row className="items-center lg:mt-[31px] xl:mt-[38px] 2xl:mt-[43px] 3xl:mt-[52px] w-[69%]">
                <Img
                  src="images/img_globe.svg"
                  className="mobile"
                  alt="globe"
                />

                <NavLink
                  to="/transfertIn"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                  as="h4"
                  variant="h4"
                >
                  Transfert <br />
                  international
                </NavLink>
              </Row>
              <Row className="items-start lg:mt-[31px] xl:mt-[38px] 2xl:mt-[43px] 3xl:mt-[52px] w-[92%]">
                <Img
                  src="images/img_trash.svg"
                  className="mobile"
                  alt="trash"
                />
                {/* <NavLink
                  to="/sb"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                  as="h4"
                  variant="h4"
                >
                  Services Bancaires
                </NavLink> */}
                <p className="rowfile md:text-center"
                  as="h4"
                  variant="h4">Services Bancaires</p>
              </Row>
              <Row className="items-start lg:ml-[3px] 2xl:ml-[4px] xl:ml-[4px] 3xl:ml-[5px] lg:mt-[34px] xl:mt-[43px] 2xl:mt-[48px] 3xl:mt-[58px] lg:pr-[13px] xl:pr-[16px] 2xl:pr-[19px] 3xl:pr-[22px] w-[90%]">
                <Img src="images/img_call.svg" className="mobile" alt="call" />
                <NavLink
                  to="/airtime"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                  as="h4"
                  variant="h4"
                >
                  Airtime
                </NavLink>
              </Row>
              <Row className="items-start lg:mt-[34px] xl:mt-[43px] 2xl:mt-[48px] 3xl:mt-[58px] lg:pr-[13px] xl:pr-[16px] 2xl:pr-[19px] 3xl:pr-[22px] w-[92%]">
                <Img
                  src="images/img_television.svg"
                  className="mobile"
                  alt="television"
                />{" "}
                {/* <NavLink
                  to="/abonnement"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                  as="h4"
                  variant="h4"
                >
                  Abonnement TV
                </NavLink> */}
                <p className="rowfile md:text-center"
                  as="h4"
                  variant="h4">Abonnement TV</p>
              </Row>
              <Row className="items-start lg:mt-[34px] xl:mt-[43px] 2xl:mt-[48px] 3xl:mt-[58px] lg:pr-[13px] xl:pr-[16px] 2xl:pr-[19px] 3xl:pr-[22px] w-[92%]">
                <Img src="images/suport_2.svg" className="mobile" />{" "}
                <NavLink
                  to="/suport"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                  as="h4"
                  variant="h4"
                >
                  Support Technique
                </NavLink>
              </Row>
            </Column>
          </Column>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
