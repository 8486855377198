import {Space} from 'antd';
import { Button } from 'components/Button';
import React, { useState } from 'react';




export default function StatusActions(props) {

    const status = props?.record?.status
    const [loading] = useState(false)
  
   
    if (loading) return <Button type="primary" shape="circle" loading />
    if (status === 'PENDING') return (
        <Button variant="FillAmberA40059">PENDING </Button>
       
    )

    if (status === 'FAILED') return (
        <Button variant="FillRedA70059"> FAILED </Button>
    )
    if (status === 'SUCCESS') return (
        <Button variant="FillLightgreen60059"> SUCCESS </Button>
    )
    return (
        <Space size='middle' >
            
        </Space>
    )

}