import {
    VALIDE_SUCCESS,
    VALIDE_FAIL,
    SET_MESSAGE,
    SET_STATUS,
  } from "./types";
  
  import AppelService from "../services/appel-service";


  export const create = (montant, username,senderId,receiverId) => (dispatch) => {
    return AppelService.create(montant, username,senderId,receiverId).then(
      (response) => {
        dispatch({
          type: VALIDE_SUCCESS,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
        dispatch({
          type: SET_STATUS,
          payload: response.data.status,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
          const status = error.response.status
  
          dispatch({
            type: VALIDE_FAIL,
          });
    
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          dispatch({
            type: SET_STATUS,
            payload:status,
          });
    
  
        return Promise.reject();
      }
    );
  };


 
  
 