import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;


const login = (username, password) => {
  return axios
    .post(API_URL + "auth/login", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.success === true) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const verifyLoginOtp = (userId,otp)=>{
  
   return axios
   .post(API_URL + "auth/verify/loginOtp", {
    userId,
    otp,
  })
  .then((response) => {
    if (response.data.success === true) {
      localStorage.removeItem("user");
      localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
  });

}

const desactive = ()=>{
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  //console.log("userId :",userId)
  const data = {
    "userId": userId,
  };
  

  const config = {
    method: 'put',
    url: API_URL + "user/logout",
    
      
    data : data
  };
  return axios(config)
 
}

const logout = () => {
  desactive()
  
  localStorage.removeItem("user");
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  logout,
  verifyLoginOtp
};