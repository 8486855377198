import {
    VALIDE_SUCCESS,
    VALIDE_FAIL,
    SET_MESSAGE,
    SET_STATUS
  } from "./types";
  
  //import WAVEService from "../services/wave-service";
  import GTUService from "../services/gtu-mm-service"

export const pay = (customerNum, amount,userId) => (dispatch) => {
    return GTUService.transaction(customerNum, "Wave","payment",amount,userId).then(
      (response) => {
        dispatch({
          type: VALIDE_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
        dispatch({
          type: SET_STATUS,
          payload: response.data.status,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

          const status = error.response.status
  
        dispatch({
          type: VALIDE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        
        dispatch({
          type: SET_STATUS,
          payload:status,
        });
  
        return Promise.reject();
      }
    );
  };


export const depot = (customerNum, amount,userId) => (dispatch) => {
    return GTUService.transaction(customerNum, "Wave","cashin",amount,userId).then(
      (response) => {
        dispatch({
          type: VALIDE_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
        dispatch({
          type: SET_STATUS,
          payload: response.data.status,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

          const status = error.response.status
  
        dispatch({
          type: VALIDE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        dispatch({
          type: SET_STATUS,
          payload:status,
        });
  
        return Promise.reject();
      }
    );
  };