import axios from "axios";
import authHeader from "./auth-header";
const API_URL = process.env.REACT_APP_API_URL;

const transaction = (number,phone,operation, amount,userId) => {

  const data = JSON.stringify({
    "amount": amount,
    "number": number,
    "phone":phone,
    "operation":operation,
    "userId": userId
  });
  

  const config = {
    method: 'post',
    url: API_URL + "transaction/",
    headers: authHeader(),
      
    data : data
  };
  return axios(config)
  
};

const transactionSenEau = (number,phone,service,operation, amount,userId) => {

  const data = JSON.stringify({
    "amount": amount,
    "number": number,
    "phone":phone,
    "service":service,
    "operation":operation,
    "userId": userId
  });
  

  const config = {
    method: 'post',
    url: API_URL + "transaction/",
    headers: authHeader(),
      
    data : data
  };
  return axios(config)
  
};

const getInfos = (reference,service,operation,userId) => {

  const data = JSON.stringify({
    "reference": reference,
    "service": service,
    "operation":operation,
    "userId": userId
  });
  

  const config = {
    method: 'post',
    url: API_URL + "transaction/infos",
    headers: authHeader(),
      
    data : data
  };
  return axios(config)
  
};


// eslint-disable-next-line import/no-anonymous-default-export
export default {
   transaction ,getInfos ,transactionSenEau
}