import { Space,  Button } from 'antd';
import React, { useState } from 'react';


import { FcInfo } from "react-icons/fc";

import {ModalhomeModal} from '../../modals/Modalhome'



export default function BankerActions(props) {

    const [isOpenModalhomeModal, setModalhomeModal] = React.useState(false);
    
    const showModal = () => {
      
        setModalhomeModal(true);
        
      };
    
     
    
      function handleCloseModalhomeModal() {
        setModalhomeModal(false);
        window.location.reload();
        //dispatch(clearMessage()); // clear message when changing location
    
      }

     
    const [loading] = useState(false)
  
    if (loading) return <Button type="primary" shape="circle" loading />
    return (
        <>
        <Space size="small">
            <Button 
                    icon={<FcInfo  size="25px"  />}
                     type="text" onClick={ showModal} 
            />
            
         </Space> 
          {isOpenModalhomeModal ? (
            <ModalhomeModal
              isOpen={isOpenModalhomeModal}
              onRequestClose={handleCloseModalhomeModal}
              record = {props.record}
              
            />
          ) : null} 
          </>
        
        
    )
}