import React, { useState, useEffect } from "react";

import { Row, Stack, Column, Button, Img, Text } from "../../components";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useNavigate, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Sidebarleft from "../../components/Sidebarleft";
import ListMobile from "../../components/ListMobile";
import UserService from "../../services/user-service";
import { EyeInvisibleTwoTone,EyeTwoTone } from '@ant-design/icons';
//import CheckOnlineStatus from "components/CheckOnlineStatus";

const Home1Page = () => {
  let navigate = useNavigate();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [showBalanceIcon, setShowBalanceIcon] = useState(false);

  const [balance, setBalance] = useState("");
  let calssSoled = true;

  // const classNameSolde = "text-amber_A700 font-montserrat font-bold lg:text-[21px] xl:text-[20px] 2xl:text-[30px] 3xl:text-[36px]";
  // const _classNameSolde = "text-bluegray_600 font-montserrat font-bold lg:text-[10px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[36px]";

  useEffect(
    () => {
      //   if (!currentUser || !isLoggedIn) {
      //     navigate("/");
      //  }
      if (currentUser) {
        UserService.getBalance(currentUser.response.user.id).then(
          (response) => {
            setBalance(response.data);
          },
          (error) => {
            // eslint-disable-next-line
            const _content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();

            //setBalance(_content);
          }
        );
      }
    },
    // eslint-disable-next-line
    []
  );
  //   if (!isLoggedIn) {
  //     return navigate("/");
  //  }

  if (100000 >= parseInt(balance.solde)) {
    calssSoled = false;
  }

  function handleBalanceIconClick() {
    setShowBalanceIcon(!showBalanceIcon);
  }

  function handleNavigate() {
    navigate("/om");
  }
  function handleNavigateWave() {
    navigate("/wave");
  }
  function handleNavigateFree() {
    navigate("/free");
  }
  function handleNavigateWizall() {
    navigate("/wizall");
  }

  if (!isLoggedIn) {
    return <Navigate replace to="/" />;
  } else {
    return (
      <>
        <Row className="bg-bluegray_50 font-montserrat items-center mx-[auto] lg:pr-[21px] xl:pr-[27px] 2xl:pr-[30px] 3xl:pr-[36px] w-[100%]">
          <Sidebar />
          <Stack className="3xl:h-[1050px] lg:h-[780px] xl:h-[900px] 2xl:h-[1040px] lg:ml-[20px] xl:ml-[26px] 2xl:ml-[29px] 3xl:ml-[35px] w-[80%]">
            <Sidebarleft
              username={currentUser ? currentUser.response.user.username : null}
            />

            <Column className="absolute justify-start left-[0] top-[0] w-[78%]">
              <Column className="bg-gray_50 font-inter items-center justify-end lg:p-[20px] xl:p-[26px] 2xl:p-[29px] 3xl:p-[35px] rounded-radius16 w-[100%]">
                {/* <CheckOnlineStatus></CheckOnlineStatus> */}
                {showBalanceIcon?
             (<Row className="items-center justify-center lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[36%]">
             <Text className="text-black_900 w-[auto]" as="h1" variant="h1">
               <span className="text-gray_600 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                 Solde{" "}
               </span>
               <span  className={ calssSoled ? "text-bluegray_600 p-2 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" : "text-amber_A700 p-2 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" } >
               ******** Fcfa
                 </span>
             </Text>
             <button
             onClick={handleBalanceIconClick}> 
               <EyeTwoTone twoToneColor="#674788" size="60px" /> 
             </button>
             {/* <Img
               src="images/img_ueyeslash.svg"
               className="lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px] "
               alt="ueyeslash"
               onClick={handleBalanceIconClick}
             /> */}
           </Row>)
             :(
              <Row className="items-center justify-center lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[36%]">
              <Text className="text-black_900 w-[auto]" as="h1" variant="h1">
                <span className="text-gray_600 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                  Solde{" "}
                </span>
                <span  className={ calssSoled ? "text-bluegray_600 p-2 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" : "text-amber_A700 p-2 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" } >
                   {new Intl.NumberFormat('de-DE').format(balance.solde)} Fcfa
                  </span>
              </Text>
              <button  onClick={handleBalanceIconClick}> <EyeInvisibleTwoTone  twoToneColor="#674788" size="50px" title=" Masquer Solde"/> </button>
              {/* <Img
                src="images/img_ueyeslash.svg"
                className="lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px] "
                alt="ueyeslash"
                onClick={handleBalanceIconClick}
              /> */}
            </Row>
             )}
                {/* <Stack className="lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px] lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[30%]">
                  {showBalanceIcon ? (
                    <Row className="absolute items-center justify-between w-[100%]">
                      <Text
                        className=" text-black_900 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        <span className="text-gray_600 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                          Solde{" "}
                        </span>

                        <span
                          className={
                            calssSoled
                              ? "text-bluegray_600 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]"
                              : "text-amber_A700 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]"
                          }
                        >
                          ********
                        </span>
                        <span
                          className={
                            calssSoled
                              ? "text-bluegray_600 font-montserrat font-bold lg:text-[8px] xl:text-[12px] 2xl:text-[14px] 3xl:text-[14px]"
                              : "text-amber_A700 font-montserrat font-bold lg:text-[8px] xl:text-[8px] 2xl:text-[8px] 3xl:text-[8px]"
                          }
                        >
                          Fcfa
                        </span>
                      </Text>

                      <Img
                        src="images/img_ueyeslash.svg"
                        className="lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px] "
                        onClick={handleBalanceIconClick}
                        alt="ueyeslash"
                      />
                    </Row>
                  ) : (
                    <Row className="absolute items-center justify-between w-[100%]">
                      <Text
                        className="   text-black_900 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        <span className="text-gray_600 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                          Solde
                        </span>
                      </Text>
                      <Text
                        className="   text-black_900 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        <span
                          className={
                            calssSoled
                              ? "text-bluegray_600 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]"
                              : "text-amber_A700 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]"
                          }
                        >
                          {new Intl.NumberFormat("de-DE").format(balance.solde)}
                          <span
                            className={
                              calssSoled
                                ? "text-bluegray_600 font-montserrat font-bold lg:text-[8px] xl:text-[12px] 2xl:text-[14px] 3xl:text-[14px]"
                                : "text-amber_A700 font-montserrat font-bold lg:text-[8px] xl:text-[8px] 2xl:text-[8px] 3xl:text-[8px]"
                            }
                          >
                            Fcfa
                          </span>
                        </span>
                      </Text>
                      <Img
                        src="images/img_ueyeslash.svg"
                        className="lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px] "
                        onClick={handleBalanceIconClick}
                        alt="ueyeslash"
                      />
                    </Row>
                  )}
                </Stack> */}
                <Column className="font-montserrat justify-start lg:mt-[23px] xl:mt-[29px] 2xl:mt-[33px] 3xl:mt-[40px] w-[99%]">
                  <Text
                    className="font-semibold text-black_900 w-[auto]"
                    as="h4"
                    variant="h4"
                  >
                    Wallets
                  </Text>
                  <br />
                  <Stack className="lg:h-[104px] xl:h-[130px] 2xl:h-[146px] 3xl:h-[175px] lg:mt-[12px] xl:mt-[16px] 2xl:mt-[18px] 3xl:mt-[21px] w-[100%]">
                    <div className="absolute bg-gray_51 bottom-[0] left-[42%] test_27332168912906_One"></div>
                    <Column className="absolute bottom-[1%] items-center justify-start w-[100%]">
                      <Column className="justify-start w-[100%]">
                        <Row className="items-center justify-between w-[100%]">
                          <Row className="items-center justify-between w-[80%]">
                            <Row
                              className="btn common-pointer bg-gray_100 items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius12 w-[24%]"
                              onClick={handleNavigate}
                            >
                              <Button
                                className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
                                variant="icbFillWhiteA701"
                              >
                                <Img
                                  src="images/img_group266.png"
                                  className="flex items-center justify-center lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px]"
                                  alt="Group270"
                                />
                              </Button>
                              <Text
                                className="rowgroup271"
                                as="h3"
                                variant="h3"
                              >
                                Orange <br />
                                money
                              </Text>
                            </Row>
                            <Row
                              className=" disabledbutton bg-gray_100 items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius12 w-[24%]"
                              onClick={handleNavigateFree}
                            >
                              <Button
                                className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
                                variant="icbFillWhiteA701"
                              >
                                <Img
                                  src="images/img_group253.png"
                                  className="flex items-center justify-center"
                                  alt="Group271"
                                />
                              </Button>
                              <Text
                                className="rowgroup271"
                                as="h3"
                                variant="h3"
                              >
                                Free
                                <br />
                                Money
                              </Text>
                            </Row>
                            <Row
                              className="btn bg-gray_100 items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius12 w-[24%]"
                              onClick={handleNavigateWave}
                            >
                              <Button
                                className=" flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
                                variant="icbFillCyan500"
                              >
                                <Img
                                  src="images/img_group265.png"
                                  className="flex items-center justify-center"
                                  alt="Group272"
                                />
                              </Button>
                              <Text
                                className="rowgroup272"
                                as="h3"
                                variant="h3"
                              >
                                Wave
                              </Text>
                            </Row>
                            <Row
                              onClick={handleNavigateWizall}
                              className=" disabledbutton bg-gray_100 items-center 2xl:p-[10px] 3xl:p-[12px] lg:p-[7px] xl:p-[9px] rounded-radius12 w-[24%]"
                            >
                              <Img
                                src="images/img_unnamed13.png"
                                className="lg:h-[32px] xl:h-[39px] 2xl:h-[44px] 3xl:h-[53px] rounded-radius8 w-[32%]"
                                alt="unnamedTwelve"
                              />
                              <Text
                                className="rowgroup272"
                                as="h3"
                                variant="h3"
                              >
                                Wizall
                              </Text>
                            </Row>
                          </Row>
                          <Row className=" disabledbutton bg-gray_100 items-center justify-center 2xl:p-[10px] 3xl:p-[12px] lg:p-[7px] xl:p-[9px] rounded-radius12 w-[19%]">
                            <Img
                              src="images/img_unnamed3.png"
                              className="lg:h-[31px] xl:h-[39px] 2xl:h-[43px] 3xl:h-[52px] mb-[1px] ml-[1px] w-[31%]"
                              alt="unnamedTwo"
                            />
                            <Text
                              className="font-medium leading-[98.02%] lg:ml-[10px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[18px] text-black_900_cc w-[auto]"
                              as="h3"
                              variant="h3"
                            >
                              Ecobank
                              <br />
                              Wallet
                            </Text>
                          </Row>
                        </Row>
                        <Row className=" disabledbutton items-center lg:mt-[10px] xl:mt-[13px] 2xl:mt-[15px] 3xl:mt-[18px] w-[59%]">
                          <Row className="items-center justify-between w-[66%]">
                            <Row className="bg-gray_100 items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius12 w-[48%]">
                              <Img
                                src="images/img_27332168912906.png"
                                className="test_27332168912906_One"
                                alt="27332168912906 One"
                              />
                              <Text
                                className="rowgroup272"
                                as="h3"
                                variant="h3"
                              >
                                Kpay
                              </Text>
                            </Row>
                            <Row className="disabledbutton  bg-gray_100 items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius12 w-[48%]">
                              <Button
                                className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
                                variant="icbFillWhiteA700"
                              >
                                <Img
                                  src="images/img_group273.png"
                                  className="flex items-center justify-center"
                                  alt="Group273"
                                />
                              </Button>
                              <Text
                                className="rowgroup271"
                                as="h3"
                                variant="h3"
                              >
                                Coris
                                <br />
                                Money
                              </Text>
                            </Row>
                          </Row>

                          <Row className=" disabledbutton bg-gray_100 items-center xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] lg:p-[12px] xl:p-[15px] 2xl:p-[17px] 3xl:p-[20px] rounded-radius12 w-[34%]">
                            <Button
                              className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
                              variant="icbFillWhiteA700"
                            >
                              <Img
                                src="images/img_1200x630wa2.png"
                                className="flex items-center justify-center"
                                alt="Group273"
                              />
                            </Button>
                            <Text
                              className="font-medium leading-[80.02%] lg:ml-[10px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[18px] text-black_900_cc w-[30%]"
                              as="h3"
                              variant="h3"
                            >
                              Kashkash
                            </Text>
                          </Row>
                        </Row>
                      </Column>
                    </Column>
                  </Stack>
                </Column>
              </Column>

              <Text className="columnhistoriquedes" as="h3" variant="h3">
                Historique des transactions
              </Text>
              {currentUser ? (
                <ListMobile userId={currentUser.response.user.id} />
              ) : null}

              {/* <ListMobile  userId={currentUser ? (currentUser.response.user.id):null} /> */}
            </Column>
          </Stack>
        </Row>
      </>
    );
  }
};

export default Home1Page;
